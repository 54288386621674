import React, { useEffect } from 'react';
import AboutUsTabs from '../Components/AboutUsTabs';
import TeamSection from '../Components/TeamSection';
import { Helmet } from 'react-helmet';

const About = () => {
  useEffect(() => {
    // Dynamically add the Elfsight script
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>About GK Promoters - Trusted Builders</title>
        <meta name="title" content="About GK Promoters - Trusted Builders" />
        <meta property="og:title" content="About GK Promoters - Trusted Builders" />
        <meta
          name="description"
          content="Learn about GK Promoters, experts in building high-quality 2BHK & 3BHK flats in Chennai’s prime locations."
        />
        <meta
          property="og:description"
          content="Learn about GK Promoters, experts in building high-quality 2BHK & 3BHK flats in Chennai’s prime locations."
        />
        <meta
          name="keywords"
          content="about GK Promoters, builders in Velachery, flat promoters Madipakkam, top builders Chennai"
        />
        <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "name": "About Us - GK Promoters",
    "url": "https://www.gkpromoters.com/about",
    "description": "Learn more about GK Promoters, a leading real estate promoter in Chennai specializing in 2BHK and 3BHK flats for sale.",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        { "@type": "ListItem", "position": 1, "name": "Home", "item": "https://www.gkpromoters.com/" },
        { "@type": "ListItem", "position": 2, "name": "About Us", "item": "https://www.gkpromoters.com/about" }
      ]
    }
  })}
</script>

      </Helmet>
      <AboutUsTabs />
      <TeamSection />
      {/* Elfsight Widget */}
      <div className="flex justify-center items-center my-10">
        <div
          className="elfsight-app-bdbaffbf-26bc-485c-a0db-a8facec6e0f2"
          data-elfsight-app-lazy
        ></div>
      </div>
    </div>
  );
};

export default About;
