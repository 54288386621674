import React, { useState } from "react";
import { FaCar, FaFingerprint } from "react-icons/fa";
import { GiCctvCamera, GiElevator, GiPowerGenerator, GiGardeningShears } from "react-icons/gi";
import { MdBalcony } from "react-icons/md";
import Faddmap from "../Components/faddmap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Santhosh = () => {
  const [activeTab, setActiveTab] = useState("About");

  // Location Data
  const locationData = [
    { place: "Hotel Nakshtra", distance: "Walking Distance" },
    { place: "Pon Vidhyasram School", distance: "5 mins" },
    { place: "Madipakkam Bus Stop", distance: "10 mins" },
    { place: "Velachery Railway Station", distance: "15 mins" },
  ];

  // About Details
  const aboutDetails = {
    units: "6",
    landArea: "66297 ",
    SaleableArea: "1051 - 1353",
    approval: "GCP Approved",
    possessionDate: "June 2026",
    pricePerSqFt: "₹7,400/sq.ft",
  };

  // Amenities Data
  const amenities = [
    { icon: <FaCar />, title: "Covered Car Parking" },
    { icon: <GiElevator />, title: "Lift Facility" },
    { icon: <GiCctvCamera />, title: "CCTV Coverage" },
    { icon: <GiGardeningShears  />, title: "Landscaping" },
  ];

  const tabs = [
    {
      title: "About",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">About</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Units:</strong> {aboutDetails.units}</li>
            <li><strong>Land Area:</strong> {aboutDetails.landArea}</li>
            <li><strong>Saleable Area:</strong> {aboutDetails.SaleableArea}</li>
            <li><strong>Approval:</strong> {aboutDetails.approval}</li>
            <li><strong>Possession Date:</strong> {aboutDetails.possessionDate}</li>
            <li><strong>Price per Sq.Ft:</strong> {aboutDetails.pricePerSqFt}</li>
          </ul>
        </>
      ),
    },
    {
      title: "Features",
      content: (
        <>
  <h2 className="text-xl font-bold mb-4">Features</h2>
  <ul className="list-disc pl-6 space-y-2">
    <li>Prime location in Madipakkam with excellent connectivity to key areas like Velachery and GST Road.</li>
    <li>Modern amenities including covered car parking, lift facilities, and CCTV coverage for enhanced security.</li>
    <li>Well-designed 2BHK and 3BHK apartments with optimal utilization of space and premium finishes.</li>
    <li>Beautifully landscaped gardens for a serene and aesthetic environment.</li>
    <li>Close proximity to reputed schools like Pon Vidyasram School and major transportation hubs.</li>
    <li>Convenient access to shopping, dining, and healthcare facilities.</li>
    <li>Sustainable design with natural ventilation and energy-efficient infrastructure.</li>
  </ul>
</>
      ),
    },
    {
      title: "Location",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Location</h2>
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2">Place</th>
                <th className="border border-gray-400 px-4 py-2">Distance</th>
              </tr>
            </thead>
            <tbody>
              {locationData.map((item, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 px-4 py-2">{item.place}</td>
                  <td className="border border-gray-400 px-4 py-2">{item.distance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ),
    },
    {
      title: "Amenities",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Amenities</h2>
          <ul className="list-disc pl-6 space-y-2">
            {amenities.map((amenity, index) => (
              <li key={index} className="flex items-center space-x-2">
                {amenity.icon}
                <span>{amenity.title}</span>
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: "Configuration",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Configuration</h2>
          <table className="bg-white w-full container mx-auto mt-5 rounded-2xl text-slate-900 border-separate shadow-none">
            <thead>
              <tr>
                <th className="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                <th className="bg-crimson-700 text-slate-50 p-2 w-auto">Type</th>
                <th className="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
              </tr>
            </thead>
            <tbody>
              {[
                { flatNo: "F1/S1/T1", type: "3BHK", size: "1353" },
                { flatNo: "F2", type: "2BHK", size: "1051" },
                { flatNo: "S2/T2", type: "2BHK", size: "1128" },
              ].map((config, index) => (
                <tr key={index} className="bg-slate-100 text-center text-2xl text-slate-900">
                  <td className="p-2">{config.flatNo}</td>
                  <td className="p-2">{config.type}</td>
                  <td className="p-2">{config.size}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ),
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>GK Santhosh Flats by GK Promoters - 2BHK & 3BHK Flats For Sale in Madipakkam</title>
        <meta name="title" content="GK Santhosh Flats by GK Promoters - 2BHK & 3BHK Flats For Sale in Madipakkam" />
        <meta property="og:title" content="GK Santhosh Flats by GK Promoters - 2BHK & 3BHK Flats For Sale in Madipakkam" />
        <meta name="description" content="Discover Santhosh by GK Promoters: Premium 2BHK & 3BHK flats For Sale in Madipakkam. Located near Nakashtra Hotel and Velachery Railway Station." />
        <meta property="og:description" content="Discover Santhosh by GK Promoters: Premium 2BHK & 3BHK flats For Sale in Madipakkam. Located near Nakashtra Hotel and Velachery Railway Station." />
        <meta name="keywords" content="GK Santhosh Flats, Madipakkam apartments, 2BHK near Nakashtra Hotel, 3BHK near Velachery Railway Station, GK Promoters properties" />
        <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ResidentialBuilding",
    "name": "GK Santhosh Flats - Madipakkam",
    "url": "https://www.gkpromoters.com/santhosh",
    "description": "Discover GK Santhosh Flats by GK Promoters, offering premium 2BHK and 3BHK flats for sale in Madipakkam with modern amenities.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Madipakkam",
      "addressRegion": "Chennai",
      "postalCode": "600091",
      "addressCountry": "IN"
    },
    "numberOfRooms": "2-3",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "Starting from ₹85 Lakhs",
      "priceCurrency": "INR"
    }
  })}
</script>

      </Helmet>
      {/* Project Title */}
      <h1 className="text-3xl font-bold text-center mb-6">GK Santhosh Flats: Premium 2BHK & 3BHK Flats for Sale in Madipakkam, By GK Promoters</h1>

      {/* Layout with Image and Tabs */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Image Section */}
        <div className="w-full lg:w-1/2">
          <img
            src="./images/Santhosh.webp" // Replace with your image path
            alt="Santhosh"
            className="rounded-lg shadow-lg w-full"
            lodaing="lazy"
          />
        </div>

        {/* Tabs Section */}
        <div className="w-full lg:w-1/2">
          <div className="grid grid-cols-3 gap-4 text-lg font-semibold">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(tab.title)}
                className={`py-2 ${
                  activeTab === tab.title
                    ? "text-red-600 border-b-2 border-red-600"
                    : "text-gray-700"
                }`}
              >
                {tab.title}
              </button>
            ))}
          </div>

          {/* Tab Content */}
          <div className="p-6 bg-gray-50 rounded-lg mt-4 shadow-sm">
            {tabs.find((tab) => tab.title === activeTab)?.content}

            {/* Get Quote Button (Visible on All Tabs) */}
            <div className="mt-6">
              <Link to="/Contact">
                <button className="bg-crimson-600 hover:bg-crimson-800  text-lg font-bold py-2 px-4 rounded-lg w-1/3">
                  Get Quote
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-10 px-6 md:px-12 lg:px-20">
      <div className="max-w-5xl mx-auto">
  {/* Title Section */}
  <div className="text-center mb-12">
    <h2 className="text-3xl md:text-4xl font-bold text-gray-900">Discover GK Santhosh Flats, Madipakkam 🏡</h2>
    <p className="text-lg text-gray-600 mt-2">The Perfect Blend of Comfort and Convenience 🌟</p>
  </div>

  {/* Introduction */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Welcome to Elevated Living in Madipakkam 🏠</h2>
    <p className="text-gray-700 leading-relaxed">
      If you’re searching for flats for sale in Madipakkam, look no further than GK Santhosh Flats by GK Promoters. This premium residential project, located near the Srinivasa Nagar Bus Stop, offers spacious 2BHK flats and 3BHK apartments. Designed to cater to families, working professionals, and retirees, GK Santhosh Flats redefines urban living by combining modern conveniences with a peaceful environment.
    </p>
  </section>

  {/* Prime Location Advantages */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Prime Location Advantages 📍</h2>
    <p className="text-gray-700 leading-relaxed">
      GK Santhosh Flats enjoys an enviable location, offering excellent connectivity and proximity to essential amenities:
    </p>
    <ul className="list-disc list-inside text-gray-700 mt-4">
      <li>Srinivasa Nagar Bus Stop: Just a short walk away for easy access to public transport. 🚌</li>
      <li>Velachery Railway Station: Only 15 minutes away for seamless connectivity across Chennai. 🚆</li>
      <li>Madipakkam Bus Stop: 10 minutes away, providing multiple commuting options. 🛣️</li>
      <li>OMR IT Corridor: A 15-minute drive, ideal for professionals working in IT hubs. 🏢</li>
      <li>Educational Institutions: Close proximity to reputed schools like Pon Vidyasram and Vyasa Vidyalaya. 📚</li>
      <li>Shopping and Entertainment: Nearby malls such as Grand Square Mall and Phoenix MarketCity ensure vibrant leisure options. 🛍️🎥</li>
    </ul>
  </section>

  {/* Nearby Schools and Hospitals */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Nearby Schools and Hospitals 🏫🏥</h2>

    <h3 className="text-xl font-semibold text-gray-700">Proximity to Quality Education 📚</h3>
    <ul className="list-disc list-inside text-gray-700 mt-4">
      <li>Pon Vidyasram School: Renowned for its strong academic curriculum and extracurricular activities, ensuring holistic development for children.</li>
      <li>Vyasa Vidyalaya Matriculation Higher Secondary School: A reputed institution offering high-quality education in a nurturing environment.</li>
    </ul>

    <h3 className="text-xl font-semibold text-gray-700 mt-6">Reliable Healthcare Nearby 🏥</h3>
    <ul className="list-disc list-inside text-gray-700 mt-4">
      <li>Dr. Kennedy's Saraswathy Multispeciality Hospital: A trusted healthcare provider </li>
      <li>XCELLENT CARE HOSPITAL offering comprehensive medical services.</li>
    </ul>
  </section>

  {/* Plot Details */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Plot Details 📏</h2>
    <p className="text-gray-700 leading-relaxed">
      The total plot measures 2,860 sq.ft. and is <strong>North-facing</strong>, ensuring a serene and well-ventilated living experience. The project is Vastu-compliant, making it an ideal choice for those seeking harmony and balance in their homes.
    </p>
  </section>

  {/* Flat Details */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Flat Details at GK Santhosh Flats 🏡</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
      GK Santhosh Flats offers meticulously crafted 2BHK and 3BHK apartments designed for modern living:
    </p>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="p-4 bg-gray-100 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-800">Flat No. F1/S1/T1 (3 BHK)</h3>
        <p className="text-gray-700 mt-2">
          1,353 sq.ft., <strong>East-facing</strong>, UDS: 526 sq.ft. A spacious and luxurious choice for families who value comfort and functionality. 🌟
        </p>
      </div>
      <div className="p-4 bg-gray-100 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-800">Flat No. F2 (2 BHK)</h3>
        <p className="text-gray-700 mt-2">
          1,051 sq.ft., <strong>East-facing</strong>, UDS: 408 sq.ft. Compact and efficient, ideal for small families or working professionals. 🏠
        </p>
      </div>
      <div className="p-4 bg-gray-100 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-800">Flat No. S2/T2 (2 BHK)</h3>
        <p className="text-gray-700 mt-2">
          1,128 sq.ft., <strong>East-facing</strong>, UDS: 437 sq.ft. Perfect for those seeking a balanced and thoughtfully designed living space. 📐
        </p>
      </div>
    </div>
  </section>

  {/* Amenities */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Modern Amenities for Elevated Living 🌟</h2>
    <ul className="list-disc list-inside text-gray-700 mt-4">
      <li>Covered Car Parking: Secure and convenient parking spaces for all residents. 🚗</li>
      <li>Lift Facility: Easy access to all floors, ensuring comfort for families and seniors. 🛗</li>
      <li>CCTV Surveillance: Advanced security features for peace of mind. 🛡️</li>
      <li>Landscaped Gardens: Beautifully designed green spaces add aesthetic appeal and serenity. 🌳</li>
    </ul>
  </section>
{/* Frequently Asked Questions */}
<section className="mb-10 border-b border-gray-300 pb-6">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Frequently Asked Questions ❓</h2>
  <div className="space-y-4">
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q1: What types of apartments are available at GK Santhosh Flats?</summary>
      <p className="text-gray-700 mt-2">
        GK Santhosh Flats offers meticulously designed 2BHK apartments ranging from 1,051 sq.ft. to 1,128 sq.ft. and spacious 3BHK apartments up to 1,353 sq.ft., catering to small and large families.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q2: What amenities are provided for residents?</summary>
      <p className="text-gray-700 mt-2">
        Residents enjoy premium amenities such as covered car parking, lift facilities, landscaped gardens, CCTV surveillance, and more.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q3: Is the location convenient for daily commuting?</summary>
      <p className="text-gray-700 mt-2">
        Absolutely. GK Santhosh Flats is located near Srinivasa Nagar Bus Stop, Velachery Railway Station, and OMR IT Corridor, ensuring seamless connectivity to key areas in Chennai.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q4: Are there good schools and hospitals nearby?</summary>
      <p className="text-gray-700 mt-2">
        Yes, reputed schools like Pon Vidyasram and Vyasa Vidyalaya are nearby. Trusted healthcare providers such as Dr. Kennedy's Saraswathy Multispeciality Hospital and XCELLENT CARE HOSPITAL are also within close proximity.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q5: How can I schedule a site visit?</summary>
      <p className="text-gray-700 mt-2">
        To schedule a site visit, contact us at <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610-695-122</a> or email <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a>. Our team will be happy to assist you.
      </p>
    </details>
  </div>
</section>

  {/* Contact Section */}
  <section className="mb-10">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Schedule a Visit Today 📅</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
      Your dream home awaits at GK Santhosh Flats in Madipakkam. Don’t miss the opportunity to explore this incredible project. Contact us now to learn more or to schedule a site visit:
    </p>
    <ul className="list-disc list-inside text-gray-700">
    <ul className="text-gray-700 leading-relaxed">
        <li>📞 Call us: <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610-695-122</a></li>
        <li>📧 Email us: <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a></li>
        <li>🌐 Visit us: <a href="https://www.gkpromoters.com/contact" className="text-blue-600 hover:underline">www.gkpromoters.com/contact</a></li>
      </ul>
    </ul>
  </section>
  <section className="text-left mt-10">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Conclusion 🚪</h2>
  <p className="text-gray-700 leading-relaxed">
    GK Santhosh Flats in Madipakkam offers a peaceful and well-connected living experience, combining modern conveniences with a serene environment. Whether you're a working professional, a family, or a retiree, this project caters to your unique needs.
  </p>
  <p className="text-gray-700 leading-relaxed mt-4">
    Explore our other projects, including <Link className="text-blue-600 hover:underline" to="/Tharun"> GK Tharun Enclave in Velachery </Link>, <Link className="text-blue-600 hover:underline" to="/Riya"> GK Riya Enclave in Adambakkam </Link>, and <Link className="text-blue-600 hover:underline" to="/SaiShree"> GK Sai Shree Flats in Puzhuthivakkam </Link> to discover your ideal home today. 🌟
  </p>
</section>
</div>


</div>

      {/* Address and Map Section */}
      <Faddmap
        flname="GK Santhosh Flats"
        add1="Plot No.81,"
        add2="Vallal Adhiyaman Street,"
        add3="Bageerathi Nagar"
        add4="Madipakkam"
        add5="Chennai-91"
        mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.11130349807!2d80.21079807498073!3d12.964729015029093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d00501deccb%3A0x56fe3223a81384af!2sGK%20Santhosh%20Flats!5e0!3m2!1sen!2sin!4v1734790699566!5m2!1sen!2sin"
      />
    </div>
  );
};

export default Santhosh;
