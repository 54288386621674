const homecomp = [
  {
    name: "GK Tharun Enclave",
    loc: "Velachery",
    imgURL: "./images/Tharun.webp",
    buttonURL: "/Tharun",
    description:
      "Tharun Enclave in Velachery offers 2 and 3 BHK flats with modern amenities, excellent connectivity, and proximity to schools, hospitals, and IT hubs.",
  },
  {
    name: "GK Sai Shree Enclave",
    loc: "Puzhuthivakkam",
    imgURL: "./images/Sai Shree.webp",
    buttonURL: "/SaiShree",
    description:
      "Sai Shree Enclave in Puzhuthivakkam is a premium project offering spacious layouts, modern finishes, and a serene living environment for your family.",
  },
  {
    name: "GK Santhosh Flats",
    loc: "Madipakkam",
    imgURL: "./images/Santhosh.webp",
    buttonURL: "/Santhosh",
    description:
      "Santhosh Flats in Madipakkam is the perfect choice for families seeking affordable yet luxurious homes in a prime location with easy connectivity.",
  },
  {
    name: "GK Riya Enclave",
    loc: "Adambakkam",
    imgURL: "./images/Riya.webp",
    buttonURL: "/Riya",
    description:
      "Riya Enclave in Adambakkam blends modern design with functionality, offering a peaceful living space close to city conveniences and urban amenities.",
  },
];

export default homecomp;
