import React, { useState } from "react";
import { FaCar, FaFingerprint , FaHandHoldingWater } from "react-icons/fa";
import { GiCctvCamera, GiElevator, GiPowerGenerator } from "react-icons/gi";
import { MdBalcony } from "react-icons/md";
import Faddmap from "../Components/faddmap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Riya = () => {
  const [activeTab, setActiveTab] = useState("About");

  // Location Data
  const locationData = [
    { place: "GK Shetty School", distance: "Walking Distance" },
    { place: "Brindavan Nagar Bus Stop", distance: "5 mins Walk" },
    { place: "Adambakkam Railway Station", distance: "10 mins" },
    { place: "Velachery MRTS", distance: "15 mins" },
    { icon: <FaHandHoldingWater />, title: "Pure Borewater" },
  ];

  // About Details
  const aboutDetails = {
    units: "6",
    landArea: "2478",
    SaleableArea: "926 - 1160 sq.ft",
    approval: "GCP approved",
    possessionDate: "March 2026",
    pricePerSqFt: "₹9,800/sq.ft",
  };

  // Amenities Data
  const amenities = [
    { icon: <FaCar />, title: "Covered Car Parking" },
    { icon: <GiElevator />, title: "Lift Facility" },
    { icon: <GiPowerGenerator />, title: "Power Backup" },
    { icon: <GiCctvCamera />, title: "CCTV Coverage" },
    { icon: <FaFingerprint />, title: "Biometric Lock" },
  ];

  const tabs = [
    {
      title: "About",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">About</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Units:</strong> {aboutDetails.units}</li>
            <li><strong>Land Area:</strong> {aboutDetails.landArea}</li>
            <li><strong>Saleable Area:</strong> {aboutDetails.SaleableArea}</li>
            <li><strong>Approval:</strong> {aboutDetails.approval}</li>
            <li><strong>Possession Date:</strong> {aboutDetails.possessionDate}</li>
            <li><strong>Price per Sq.Ft:</strong> {aboutDetails.pricePerSqFt}</li>
          </ul>
        </>
      ),
    },
    {
      title: "Features",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Features</h2>
          <p>Explore the unique features of GK Riya Enclave designed for modern living.</p>
        </>
      ),
    },
    {
      title: "Location",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Location</h2>
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2">Place</th>
                <th className="border border-gray-400 px-4 py-2">Distance</th>
              </tr>
            </thead>
            <tbody>
              {locationData.map((item, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 px-4 py-2">{item.place}</td>
                  <td className="border border-gray-400 px-4 py-2">{item.distance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ),
    },
    {
      title: "Amenities",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Amenities</h2>
          <ul className="list-disc pl-6 space-y-2">
            {amenities.map((amenity, index) => (
              <li key={index} className="flex items-center space-x-2">
                {amenity.icon}
                <span>{amenity.title}</span>
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: "Configuration",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Configuration</h2>
          <table className="bg-white w-full container mx-auto mt-5 rounded-2xl text-slate-900 border-separate shadow-none">
            <thead>
              <tr>
                <th className="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                <th className="bg-crimson-700 text-slate-50 p-2 w-auto">Type</th>
                <th className="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
              </tr>
            </thead>
            <tbody>
              {[
                { flatNo: "F1/S1/T1", type: "2BHK", size: "926" },
                { flatNo: "F2/S2/T2", type: "3BHK", size: "1160" },
              ].map((config, index) => (
                <tr key={index} className="bg-slate-100 text-center text-2xl text-slate-900">
                  <td className="p-2">{config.flatNo}</td>
                  <td className="p-2">{config.type}</td>
                  <td className="p-2">{config.size}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ),
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>GK Riya Enclave by GK Promoters – Flats for Sale in Adambakkam</title>
        <meta name="title" content="GK Riya Enclave by GK Promoters – Flats for Sale in Adambakkam" />
        <meta property="og:title" content="GK Riya Enclave by GK Promoters – Flats for Sale in Adambakkam" />
        <meta name="description" content="Explore GK Riya Enclave by GK Promoters: Spacious 2BHK & 3BHK flats for sale in Adambakkam near GK Shetty School and Brindavan Nagar Bus Stop." />
        <meta property="og:description" content="Explore GK Riya Enclave by GK Promoters: Spacious 2BHK & 3BHK flats for sale in Adambakkam near GK Shetty School and Brindavan Nagar Bus Stop." />
        <meta name="keywords" content="GK Riya Enclave, Adambakkam properties, 2BHK Flats for Sale near GK Shetty School, 3BHK Flats for Sale near Brindavan Nagar, GK Promoters apartments" />
        <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ResidentialBuilding",
    "name": "GK Riya Enclave - Adambakkam",
    "url": "https://www.gkpromoters.com/Riya",
    "description": "Discover GK Riya Enclave by GK Promoters, offering premium 2BHK and 3BHK flats for sale in Adambakkam with modern amenities.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Adambakkam",
      "addressRegion": "Chennai",
      "postalCode": "600088",
      "addressCountry": "IN"
    },
    "numberOfRooms": "2-3",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "Starting from ₹95 Lakhs",
      "priceCurrency": "INR"
    }
  })}
</script>

      </Helmet>
      {/* Project Title */}
      <h1 className="text-3xl font-bold text-center mb-6">Riya Enclave:Premium 2BHK & 3BHK Flats for Sale in Adambakkam, By GK Promoters
      </h1>

      {/* Layout with Image and Tabs */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Image Section */}
        <div className="w-full lg:w-1/2">
          <img
            src="./images/Riya.webp" // Replace with your image path
            alt="GK Riya Enclave"
            className="rounded-lg shadow-lg w-full"
            lodaing="lazy"
          />
        </div>

        {/* Tabs Section */}
        <div className="w-full lg:w-1/2">
          <div className="grid grid-cols-3 gap-4 text-lg font-semibold">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(tab.title)}
                className={`py-2 ${
                  activeTab === tab.title
                    ? "text-red-600 border-b-2 border-red-600"
                    : "text-gray-700"
                }`}
              >
                {tab.title}
              </button>
            ))}
          </div>

          {/* Tab Content */}
          <div className="p-6 bg-gray-50 rounded-lg mt-4 shadow-sm">
            {tabs.find((tab) => tab.title === activeTab)?.content}

            {/* Get Quote Button (Visible on All Tabs) */}
            <div className="mt-6">
              <Link to="/Contact">
                <button className="bg-crimson-600 hover:bg-crimson-800 text-lg font-bold py-2 px-4 rounded-lg w-1/3">
                  Get Quote
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-10 px-6 md:px-12 lg:px-20">
      <div className="max-w-5xl mx-auto">
  {/* Title Section */}
  <div className="text-center mb-12">
    <h2 className="text-3xl md:text-4xl font-bold text-gray-900">Welcome to Riya Enclave, Adambakkam 🏠</h2>
  </div>

 

  {/* Introduction */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Your Gateway to Contemporary Living 🌟</h2>
    <p className="text-gray-700 leading-relaxed">
      Riya Enclave by GK Promoters is the epitome of contemporary urban living, nestled in the vibrant locality of Adambakkam, Chennai. Offering exquisitely designed 2 BHK and 3 BHK flats, this project brings together affordability, luxury, and convenience. Located near the Brindavan Nagar Bus Stop, Riya Enclave is an ideal choice for professionals, families, and retirees seeking a perfect balance between connectivity and serenity.
    </p>
  </section>

  {/* Prime Location Advantages */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Prime Location Advantages 📍</h2>
    <p className="text-gray-700 leading-relaxed">
      Riya Enclave’s location is thoughtfully chosen to provide unmatched connectivity and accessibility:
    </p>
    <ul className="list-disc list-inside text-gray-700 mt-4">
      <li>GK Shetty School: Perfect for families with children, just a stone’s throw away. 📚</li>
      <li>Brindavan Nagar Bus Stop: A 5-minute walk, ensuring seamless travel. 🚌</li>
      <li>Adambakkam Railway Station: Just 10 minutes away for hassle-free commutes. 🚆</li>
      <li>Velachery MRTS: Only 15 minutes away for direct city connectivity. 🌆</li>
      <li>Prime Care Nursing Home: Accessible healthcare facilities for your convenience. 🏥</li>
      <li>Excellent Hospital, Velachery: Trusted medical care just minutes away. 🏥</li>
    </ul>
  </section>

  {/* Plot Details */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Plot Details 📏</h2>
    <p className="text-gray-700 leading-relaxed">
      The total plot measures 2,480 sq.ft. and is <strong>South-facing</strong>, ensuring ample sunlight and adherence to Vastu principles. This makes Riya Enclave an excellent choice for modern living.
    </p>
  </section>

  {/* Flat Details */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Flat Details at Riya Enclave 🏡</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
      Riya Enclave offers thoughtfully crafted 2 BHK and 3 BHK apartments tailored for modern living:
    </p>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="p-4 bg-gray-100 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-800">Flat No. F1/S1/T1 (2 BHK)</h3>
        <p className="text-gray-700 mt-2">
          927 sq.ft., <strong>East-facing</strong>, UDS: 367 sq.ft. Compact and perfect for small families or professionals. 🏡
        </p>
      </div>
      <div className="p-4 bg-gray-100 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-800">Flat No. F2/S2/T2 (3 BHK)</h3>
        <p className="text-gray-700 mt-2">
          1,160 sq.ft., <strong>East-facing</strong>, UDS: 459 sq.ft. Spacious and luxurious, ideal for larger families. 🌟
        </p>
      </div>
    </div>
  </section>

  {/* Amenities */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Exceptional Amenities for Elevated Living 🌟</h2>
    <ul className="list-disc list-inside text-gray-700 mt-4">
      <li>Covered Car Parking: Ample, secure parking for all residents. 🚗</li>
      <li>Lift Facility: Safe and convenient access to all floors. 🛗</li>
      <li>Power Backup: Reliable power for common areas. 💡</li>
      <li>CCTV Surveillance: Comprehensive 24/7 security. 🛡️</li>
      <li>Biometric Locks: Advanced security for your modern home. 🔒</li>
    </ul>
  </section>

  {/* Frequently Asked Questions */}
  <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Frequently Asked Questions ❓</h2>
    <div className="space-y-4">
      <details className="bg-gray-100 p-4 rounded-lg shadow">
        <summary className="font-semibold text-gray-800 cursor-pointer">Q1: What types of apartments are available at Riya Enclave?</summary>
        <p className="text-gray-700 mt-2">
          Riya Enclave offers well-designed 2BHK apartments starting at 926 sq.ft. and spacious 3BHK apartments up to 1,160 sq.ft., catering to small and large families.
        </p>
      </details>
      <details className="bg-gray-100 p-4 rounded-lg shadow">
        <summary className="font-semibold text-gray-800 cursor-pointer">Q2: What amenities are provided for residents?</summary>
        <p className="text-gray-700 mt-2">
          Amenities include covered car parking, lift facility, power backup, CCTV surveillance and biometric lock systems.
        </p>
      </details>
      <details className="bg-gray-100 p-4 rounded-lg shadow">
        <summary className="font-semibold text-gray-800 cursor-pointer">Q3: Is the location convenient for daily commuting?</summary>
        <p className="text-gray-700 mt-2">
          Absolutely. Riya Enclave is near the Brindavan Nagar Bus Stop, Adambakkam Railway Station, and Velachery MRTS, ensuring smooth connectivity to key areas in Chennai.
        </p>
      </details>
      <details className="bg-gray-100 p-4 rounded-lg shadow">
        <summary className="font-semibold text-gray-800 cursor-pointer">Q4: Is Riya Enclave suitable for families with children?</summary>
        <p className="text-gray-700 mt-2">
          Yes, the project is close to GK Shetty School and other essential services, making it an excellent choice for families.
        </p>
      </details>
      <details className="bg-gray-100 p-4 rounded-lg shadow">
        <summary className="font-semibold text-gray-800 cursor-pointer">Q5: How can I schedule a site visit?</summary>
        <p className="text-gray-700 mt-2">
        To schedule a site visit, contact us at <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610-695-122</a> or email <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a>. Our team will be happy to assist you.
      </p>
      </details>
    </div>
  </section>

  {/* Contact Section */}
  <section className="mb-10">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Schedule a Visit to Riya Enclave Today 📅</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
      Your dream home awaits at Riya Enclave. Don’t miss the opportunity to be part of this prestigious community. Contact us now to learn more or to schedule a site visit:
    </p>

    <ul className="text-gray-700 leading-relaxed">
        <li>📞 Call us: <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610-695-122</a></li>
        <li>📧 Email us: <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a></li>
        <li>🌐 Visit us: <a href="https://www.gkpromoters.com/contact" className="text-blue-600 hover:underline">www.gkpromoters.com/contact</a></li>
      </ul>

    <p className="text-gray-700 mt-4">
      <strong>Riya Enclave by GK Promoters:</strong> Where modern living meets timeless quality. Discover your perfect home today!
    </p>
  </section>
  <section className="text-left mt-10">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Conclusion 🚪</h2>
  <p className="text-gray-700 leading-relaxed">
    GK Riya Enclave in Adambakkam is a contemporary residential project that balances affordability, convenience, and modern design. Ideal for professionals, families, and retirees, it’s your gateway to an elevated lifestyle in Chennai.
  </p>
  <p className="text-gray-700 leading-relaxed mt-4">
    Explore our other projects, including <Link className="text-blue-600 hover:underline" to="/Tharun"> GK Tharun Enclave in Velachery </Link>, <Link className="text-blue-600 hover:underline" to="/Santhosh"> GK Santhosh Flats in Madipakkam </Link>, and <Link className="text-blue-600 hover:underline" to="/SaiShree"> GK Sai Shree Flats in Puzhuthivakkam </Link> and take a step closer to owning your dream home today. 🌟
  </p>
</section>
</div>

</div>

      {/* Address and Map Section */}
      <Faddmap
        flname="GK Riya Enclave"
        add1="Plot No 3, Door No.7,"
        add2="Ganesh Nagar 1st Street,"
        add3="Adambakkam,"
        add4="Chennai-88"
        mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.801320586943!2d80.20566287587987!3d12.984555814592522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d003fa853c5%3A0x317632c8c6454210!2sGK%20Tharun%20Enclave!5e0!3m2!1sen!2sin!4v1737639969992!5m2!1sen!2sin"
      />
    </div>
  );
};

export default Riya;
