import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisairamya = () => {
  return <div>
      <Title h1title ="Sri Sai Ramya Flats - Puzhithivakkam" />
      <br />
      <Flat imgsrc="./images/Sri Sai Ramya- Annai Thersa Nagar.webp" btnurl="/Srisairamya" near1="Near Puzhuthivakkam Bus Stand" near2="Near Vyasa School" a={true} />
      <br />
<Title titles="Configuration" />
<br />

        <br />
<Faddmap flname="Sri Sai Ramya" add1="Plot No.102," add2="Annai Teresa Nagar , 1st street" add3="Puzhuthivakkam" add4="Chennai-91"   mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0421188182827!2d80.20298777584183!3d12.969156714930365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525dcbfaa229a9%3A0x19afbb7db6c4d9f1!2sGK%20Sri%20Sai%20Ramya!5e0!3m2!1sen!2sin!4v1686576639255!5m2!1sen!2sin" />
<br />
  </div>


};

export default Srisairamya;
