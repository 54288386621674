import React, { useState } from 'react';

function Tab({ title, isActive, onTabClick }) {
  return (
    <button
      className={`px-4 py-2 ${isActive ? 'text-crimson-600 border-b-2 border-crimson-600' : 'text-black'}`}
      onClick={onTabClick}
    >
      {title}
    </button>
  );
}

function AboutUsTabs() {
  const [activeTab, setActiveTab] = useState('About');

  const tabs = ['About', 'Vision', 'Mission', 'Service', 'Support'];

  return (
    
    <div className="w-full container mx-auto ">
      <div className="border-b-2 border-gray-200  text-xl ">
        {tabs.map(tab => (
          <Tab
            key={tab}
            title={tab}
            isActive={activeTab === tab}
            onTabClick={() => setActiveTab(tab)}
          />
        ))}
      </div>
      <br />
      <div className="p-4 bg-crimson-600 border-collapse shadow-lg  rounded-xl text-slate-50 ">
        {activeTab === 'About' && 
        <div className='text-lg  '>
<h1>Welcome to GK Promoters, Chennai, TamilNadu! Having been a veteran in the residential construction  field, we take pride in delivering exceptional construction services to our clients. With over two decades of experience, we have honed our skills and built a reputation for excellence.</h1>
<b />
<p>Our company has successfully completed numerous flat construction projects for clients with varying preferences and needs over the years. Our team of experienced professionals, including architects, engineers, and skilled craftsmen, work diligently to bring our clients' visions to life.</p>
<b />
<p>We realize buying a house is a substantial investment, and we prioritize open and transparent communication with our clients throughout the entire process.From the beginning of the project to its end, we strive to ensure that our clients stay involved and informed. We believe that an interactive approach produces the best outcomes and elicits customer satisfaction.</p>
<b />
<p>We are incredibly grateful that you have shown interest in GK Promoters and we look forward to the opportunity to collaborate with you. Please do not hesitate to reach out to us and let us demonstrate how we can make your dream home a reality.</p>
</div>}
        {activeTab === 'Vision' && 
        <div className='text-lg'>
<p>From the very beginning, our goal has been to create homely spaces for people. This mission continues to be central in all our initiatives. We are committed to ensuring that our flats are constructed with utmost quality and that they satisfy the requirements of our customers.</p>
<b />
<p>Our team is devoted to delivering a streamlined and satisfying building experience for your ideal home. We handle all facets of the project, ranging from the initial planning and design to construction and final touches. We strive to craft living spaces that fulfill your individual requirements and reflect your personal style.</p>

</div>}
        {activeTab === 'Mission' && 
        <div className='text-lg'>
          Quality is our top priority. We go to great lengths to ensure that the materials used for constructing our apartments are of good quality and sourced from reliable suppliers. Moreover, we take extra care in every step of the process to ensure that we deliver a product with impeccable craftsmanship and meticulous detail. Furthermore,
          <ul className='list-disc  ml-5'>
            <li className=' py-1'>We strive to deliver outstanding construction services that surpass our client's expectations.</li>
            <li className=' py-1'>Our flats are designed to provide aesthetic appeal, functionality, and durability of the highest order.</li>
            <li className=' py-1'>To build strong and lasting connections with clients, trust, honesty and excellent customer service are essential.</li>
            <li className=' py-1'>We provide on-time delivery.</li>
        </ul>
</div>}
        {activeTab === 'Service' &&  
          <div className='text-lg'>
       Providing a range of services tailored to meet the varying needs of our clients is one of our core values at GK Promoters. We guarantee high-quality workmanship and competitive pricing for all the services we offer.
        <ul>
            <li><strong>Residential Building:</strong> We build distinctive homes that reflect our clients' preferences and aspirations. Elegant and comfortable homes are designed with contemporary style and practicality in mind</li>
            <li><strong>Custom Home Building:</strong> With our custom home building services, clients can expect: <br/>
A personalized approach that takes into account their lifestyle, needs, and preferences<br/>
A collaborative process that involves the client every step of the way<br/>
High-quality materials and craftsmanship<br/>
Attention to detail and a focus on delivering a home that exceeds expectations<br/>
</li>
            <li><strong>Project Management:</strong> In addition to constructing homes, we possess knowledge and skills in managing projects from start to finish. We offer comprehensive project management services to ensure a smooth and seamless execution of the project, covering all phases, from the initial planning and design stage, through to the construction phase, and finally, to the handover of the completed project.</li>
            <li><strong>Consulting Services:</strong> We provide consulting services to clients who require professional guidance on construction, design, and budget planning. We utilize our vast experience in the industry to assist clients in making well-informed decisions that align with their homeownership objectives. Our goal is to provide expert advice that will help clients achieve their desired outcomes.</li>
            <li><strong>Post-Construction Services:</strong>  We do not only prioritize our clients during the construction phase, but we also extend our commitment to them even after the project is completed. <br />
At GK Promoters, we hold our clients in high esteem, and we are dedicated to providing them with the best possible services. We continuously aim to enhance our services, with the goal of setting new benchmarks of excellence in the construction industry.
</li>
        </ul>
  
    </div>}
        {activeTab === 'Support' &&  
         <div className='text-lg'>
      We believe in providing exceptional support services to our customers and deeply value our relationship with them. Here's what we offer:

        <ul>
            <li><strong>Pre-Sales Assistance:</strong> Right from the initial contact with us, We offer all-encompassing information and support to assist you in making informed decisions about your prospective home</li>
            <li><strong>Customer Service:</strong> At all stages of the construction process, we have top-notch customer service that will address your concerns or queries. Our policy is to keep communication channels open to guarantee your contentment.</li>
            <li><strong>Post-Sales Support:</strong> We do not consider our obligation fulfilled by merely handing over your new home. We provide after-sales support, which includes property management, maintenance, and other essential services, to guarantee a seamless transition into your new abode.
</li>
            <li><strong>Quality Assurance:</strong> We take pride in the quality of our work and are committed to addressing any issues that may arise after construction promptly and efficiently..</li>
        </ul>
<br />
        Our priority at GK Promoters is not limited to constructing homes, but it also involves establishing enduring relationships with our clients. Our pledge is to provide unwavering support throughout the entire process, starting from the first interaction to the post-construction phase.    </div>}
      </div>


    </div>
    
  );
}

export default AboutUsTabs;
