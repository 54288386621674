import React from "react";
import Title from "../Components/title";
import compimg from "../Components/Compimg";
import Completedpjsx from "../Components/Completedp";
import { Helmet } from 'react-helmet'
function CompletedProjects() {
  return (
    <div class=" text-xl container mx-auto text-gray-800 rounded-2xl">
      <Helmet>
          <title>Completed Projects by GK Promoters</title>
          <meta name="title" content="Completed Projects by GK Promoters" />
<meta property="og:title" content="Completed Projects by GK Promoters" />
<meta name="description" content="Check out our portfolio of completed projects featuring modern homes across Chennai’s prime locations." />
<meta property="og:description" content="Check out our portfolio of completed projects featuring modern homes across Chennai’s prime locations." />
<meta name="keywords" content="completed flats Velachery, ready-to-move apartments Chennai, GK Promoters past projects" />

      </Helmet>
      <Title h1title="Completed projects" />
      <div className="flex flex-wrap ">
        {compimg &&
          compimg.map((Completedp) => (
            <Completedpjsx
              name={Completedp.name}
              img={Completedp.imgURL}
              loc={Completedp.loc}
              button={Completedp.buttonURL}
            />
            
          ))
          } 
        
      </div>
   
    </div>
  );
}
export default CompletedProjects;
