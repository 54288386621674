import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisaiaditiya = () => {
  return <div>
      <Title h1titles="Sri Sai Aditiya Flats - Puzuthivakkam" />
      <br />
      <Flat imgsrc="./images/Completed Projects/Sri Sai Aditya.webp" btn={true}  />

<Faddmap flname="Sri Sai Aditiya" add1="plot No.A," add2="Sarathy Nagar," add3="1st Street,"  add4="Puzhuthivakkam" add5="chennai-91"  mapurl="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15551.796116299363!2d80.2118705970681!3d12.975111954199686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525dd9195dc2a5%3A0x5397f41469918ee2!2sSri%20sai%20adithya%20flats!5e0!3m2!1sen!2sin!4v1643047610065!5m2!1sen!2sin" />
  <br />
  </div>


};

export default Srisaiaditiya;
