import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Rakeshpranav = () => {
  return <div>
      <Title titles="Rakesh-Pranav Flats -Madipakkam" />
      <br />
      
      <Flat imgsrc="./images/Completed Projects/Rakesh-Pranav Flats - Periyar Nagar.webp" btn={false}  />

<Faddmap add1="plot No.52," add2="Periyar Nagar," add3="Ambedkar Salai Extension,"  add4="Madipakkam" add5="chennai-91"  mapurl="
https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.94113955147!2d80.1991422!3d12.9567907!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x79a7a657904c91d8!2sGK%20Rakesh%20Pranav%20Flats!5e0!3m2!1sen!2sin!4v1643047178779!5m2!1sen!2sin" />
<br />
  </div>


};

export default Rakeshpranav;
