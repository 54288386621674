import React from 'react';
import { Link } from 'react-router-dom';

function Currentpjsx({
  name,
  img,
  loc,
  approval,
  structure,
  type,
  area,
  units,
  possession,
  titlea,
  buttonURL,
}) {
  return (
    <div className="bg-pcard-300 rounded-2xl shadow-lg overflow-hidden flex flex-col h-full">
      <Link
        to={buttonURL}
        className="inline-block  text-white font-bold  rounded-lg hover:bg-pcard-600 transition duration-300"
      >
        {/* Image Section */}
        <div className="relative h-72">
          <img className="w-full h-full object-cover" src={img} alt={name} />
        </div>

        {/* Content Section */}
        <div className="p-6">
          <h2 className="font-bold text-xl text-gray-900 mb-2 text-center">
            {titlea || `Premium 2BHK & 3BHK Flats for Sale in ${loc}`}
          </h2>
          <h3 className="font-semibold text-xl lg:text-lg text-black">{name}</h3>
          <p className="text-lg lg:text-sm text-black mb-4">{loc}</p>
          <ul className="text-lg lg:text-sm text-black space-y-2">
            <li><strong>Approval:</strong> {approval}</li>
            <li><strong>Structure:</strong> {structure}</li>
            <li><strong>Type:</strong> {type}</li>
            <li><strong>Saleable Area:</strong> {area}</li>
            <li><strong>Total Units:</strong> {units}</li>
            <li><strong>Possession:</strong> {possession}</li>
          </ul>
        </div>
      </Link>

      {/* Button Section */}
      <div className="p-1 text-center font-semibold">
        <Link
          to={buttonURL}
          className="text-blue-600 hover:underline"
        >
          Explore {name}, {loc}
        </Link>
      </div>
    </div>
  );
}

export default Currentpjsx;
