const compimg = [
  {
    name: "Sri Sai Aditiya Flats  ",
    loc: "Puzhuthivakkam",
    imgURL: "./images/Completed Projects/Sri Sai Aditya.webp",
    buttonURL: "./Srisaiaditiya",
  },
  {
    name: "Sri Sai Shanmuga Flats",
    loc: "Adambakkam",
    imgURL: "./images/Completed Projects/Sri Sai Shanmuga.webp",
    buttonURL: "./Srisaishanmuga",
  },
  {
    name: "Sri Sai Baba Flats  ",
    loc: "S.Kolathur",
    imgURL: "./images/Completed Projects/SRI SAI BABA - S.Kolathur.webp",
    buttonURL: "./Srisaibaba",
  },
  {
    name: "Rakesh-pranav Flats ",
    loc: "Madipakkam",
    imgURL: "./images/Completed Projects/Rakesh-Pranav Flats - Periyar Nagar.webp",
    buttonURL: "./Rakeshpranav",
  },
  {
    name: "Thanshikaa Flats ",
    loc: "Madipakkam",
    imgURL: "./images/Completed Projects/Thanshikaa.webp",
    buttonURL: "./Thanshikaa",
  },
  {
    name: "Akrthi Enclave ",
    loc: "Velachery",
    imgURL: "./images/Akrthi-Padmavati-Nagar.webp",
    buttonURL: "/Akrthi",
  },
  {
    name: "Thiara Enclave ",
    loc: "Madipakkam",
    imgURL: "./images/Thiara-Bageerathi-Nagar.webp",
    buttonURL: "./Thiara",
  },
  {
    name: "Sri Sai thiara ",
    loc: "Madipakkam",
    imgURL: "./images/Sri Sai Thiara- Puzhuthivakkam.webp",
    buttonURL: "./Srisaithiara",
  },
  {
    name: "Sri Sai Ramya ",
    loc: "Madipakkam",
    imgURL: "./images/Sri Sai Ramya- Annai Thersa Nagar.webp",
    buttonURL: "./Srisairamya",
  },
];
export default compimg;
