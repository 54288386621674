import React from "react";
import Title from "./title";
import Current from "./current";
import currentimg from "./currentimg";
import homecomp from "./homecomp";

function Featured() {
  return (
    <div className="text-xl text-gray-800 rounded-2xl">
      <Title titles="Featured Projects" />
      <p className="text-gray-600 mt-2 max-w-3xl mx-auto text-center">
        Discover our premium residential projects in Chennai's top locations,
        including Velachery, Madipakkam, Puzhuthivakkam, and Adambakkam. Each
        project is designed to provide modern amenities, strategic connectivity,
        and a comfortable lifestyle.
      </p>

      <div className="flex flex-wrap -mx-4 mt-6">
        {homecomp &&
          homecomp.map((current) => (
            <Current
              key={current.name}
              name={current.name}
              img={current.imgURL}
              loc={current.loc}
              button={current.buttonURL}
              description={current.description}
            />
          ))}
      </div>
    </div>
  );
}

export default Featured;


