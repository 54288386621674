// Blog.js
import React from 'react';
import Title from '../Components/title';
import BlogPost from '../Components/Blog/BlogPost'; // Import the new BlogPost component

const Blog = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Title title="Our Blog Posts" />
      <div className="flex flex-wrap -mx-4">
        {/* Existing Blog Post: Bricks Blog */}
        <div className="w-1/3 px-4 mb-8">
        <BlogPost 
  title="Understanding the Quantity and Quality of Wire Cut Red Bricks"
  date="Jul 28, 2022"
  summary="When constructing multi-story buildings, choosing the right quantity of wire cut red bricks is crucial. These bricks not only ensure structural integrity but also contribute to energy efficiency and aesthetic appeal."
  link="/Bricksblog"
  image="https://via.placeholder.com/300" // Replace with actual image URL
/>
        </div>

        {/* Existing Blog Post: Flats Blog */}
        <div className="w-1/3 px-4 mb-8">
          <BlogPost 
            title="The Allure of Flats in Velachery and Madipakkam"
            date="Dec 26, 2024"
            summary="Discover why Velachery, Madipakkam, and surrounding areas are becoming the top choice for homebuyers in Chennai. Explore the perfect blend of convenience, affordability, and community these neighborhoods offer."
            link="/Flatsblog"
            image="https://via.placeholder.com/300" // Replace with actual image URL
          />
        </div>

        {/* New Blog Post: Trends in Real Estate Development */}
        <div className="w-1/3 px-4 mb-8">
          <BlogPost 
            title="Top 5 Trends in Real Estate Development for 2025"
            date="Jan 05, 2025"
            summary="The real estate industry is evolving rapidly, driven by advancements in technology, sustainability demands, and changing urban lifestyles. For 2025, these are the top trends shaping the future of real estate development."
            link="/Trends2025"
            image="https://via.placeholder.com/300" // Replace with actual image URL
          />
        </div>
      </div>
    </div>
  );
};

export default Blog;
