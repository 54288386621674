import React, { useState } from "react";
import { FaCar, FaFingerprint } from "react-icons/fa";
import { GiCctvCamera, GiElevator, GiPowerGenerator , GiGardeningShears } from "react-icons/gi";
import { MdBalcony } from "react-icons/md";
import Faddmap from "../Components/faddmap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Tharun = () => {
  const [activeTab, setActiveTab] = useState("About");

  // Location Data
  const locationData = [
    { place: "San Academy School", distance: "Walking Distance" },
    { place: "AGS Colony Bus Stop", distance: "5 mins" },
    { place: "Phoenix Marketcity", distance: "15 mins" },
    { place: "Velachery MRTS Station", distance: "10 mins" },
  ];

  // About Details
  const aboutDetails = {
    units: "11",
    landArea: "4440sqft",
    SaleableArea: "877 - 1113 sq.ft",
    approval: "CMDA Approved",
    possessionDate: "April 2025",
    pricePerSqFt: "₹8,700/sq.ft",
  };

  // Amenities Data
  const amenities = [
    { icon: <FaCar />, title: "Covered Car Parking" },
    { icon: <GiElevator />, title: "Lift Facility" },
    { icon: <GiPowerGenerator />, title: "Power Backup" },
    { icon: <GiCctvCamera />, title: "CCTV Coverage" },
    { icon: <MdBalcony />, title: "Private Terrace" },
    { icon: <FaFingerprint />, title: "Biometric Lock" },
    { icon: <GiGardeningShears  />, title: "Landscaping" },
  ];

  const tabs = [
    {
      title: "About",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">About</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Units:</strong> {aboutDetails.units}
            </li>
            <li>
              <strong>Land Area:</strong> {aboutDetails.landArea}
            </li>
            <li>
              <strong>Saleable Area:</strong> {aboutDetails.SaleableArea}
            </li>
            <li>
              <strong>Approval:</strong> {aboutDetails.approval}
            </li>
            <li>
              <strong>Possession Date:</strong> {aboutDetails.possessionDate}
            </li>
            <li>
              <strong>Price per Sq.Ft:</strong> {aboutDetails.pricePerSqFt}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Features",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Features</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Prime Location in Velachery with excellent connectivity to key hubs.</li>
            <li>Modern amenities including biometric locks, CCTV surveillance, and power backup.</li>
            <li>Spacious 2BHK and 3BHK flats with options for private terraces.</li>
            <li>Eco-friendly design with natural ventilation and lighting.</li>
            <li>Close proximity to schools, shopping centers, and transportation.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Location",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Location</h2>
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2">Place</th>
                <th className="border border-gray-400 px-4 py-2">Distance</th>
              </tr>
            </thead>
            <tbody>
              {locationData.map((item, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 px-4 py-2">{item.place}</td>
                  <td className="border border-gray-400 px-4 py-2">{item.distance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ),
    },
    {
      title: "Amenities",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Amenities</h2>
          <ul className="list-disc pl-6 space-y-2">
            {amenities.map((amenity, index) => (
              <li key={index} className="flex items-center space-x-2">
                {amenity.icon}
                <span>{amenity.title}</span>
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: "Configuration",
      content: (
        <>
          <h2 className="text-xl font-bold mb-4">Configuration</h2>
          <table className=" w-full container mx-auto mt-5 rounded-2xl text-slate-900 border-separate shadow-none">
            <thead>
              <tr>
                <th className="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                <th className="bg-crimson-700 text-slate-50 p-2 w-auto">Type</th>
                <th className="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
              </tr>
            </thead>
            <tbody>
              {[
                { flatNo: "F1/S1", type: "2BHK", size: "916" },
                { flatNo: "F2/S2", type: "2BHK", size: "877" },
                { flatNo: "F3/S3", type: "2BHK", size: "964" },
                { flatNo: "F4/S4", type: "2BHK", size: "974" },
                { flatNo: "T1", type: "3BHK", size: "1113" },
                { flatNo: "T2", type: "2BHK", size: "964" },
                { flatNo: "T3", type: "2BHK", size: "974" },
              ].map((config, index) => (
                <tr key={index} className="bg-slate-100 text-center text-2xl text-slate-900">
                  <td className="p-2">{config.flatNo}</td>
                  <td className="p-2">{config.type}</td>
                  <td className="p-2">{config.size}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ),
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>GK Tharun Enclave Flats for Sale in Velachery, Chennai by GK Promoters</title>
        <meta name="title" content="GK Tharun Enclave Flats for Sale in Velachery, Chennai by GK Promoters" />
        <meta name="og:title" content="GK Tharun Enclave Flats for Sale in Velachery, Chennai by GK Promoters" />
        <meta name="description" content="Premium 2BHK & 3BHK  apartments in Velachery with luxury amenities and excellent connectivity." />
        <meta name="og:description" content="Premium 2BHK & 3BHK  apartments in Velachery with luxury amenities and excellent connectivity." />
        <meta name="keywords" content="GK Tharun Enclave apartments, Velachery premium flats, luxury living Chennai, GK Promoters enclave , 2BHK Flats For sale in Velachery , 3BHK Flats For sale in Velachery " />
        <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ResidentialBuilding",
    "name": "GK Tharun Enclave - Velachery",
    "url": "https://www.gkpromoters.com/Tharun",
    "description": "Discover GK Tharun Enclave by GK Promoters, offering premium 2BHK and 3BHK flats for sale in Velachery with modern amenities.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Velachery",
      "addressRegion": "Chennai",
      "postalCode": "600042",
      "addressCountry": "IN"
    },
    "numberOfRooms": "2-3",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "Starting from ₹90 Lakhs",
      "priceCurrency": "INR"
    }
  })}
</script>

      </Helmet>
      <h1 className="text-3xl font-bold text-center mb-6">GK Tharun Enclave: Premium 2BHK & 3BHK Flats for Sale in Velachery, By GK Promoters</h1>
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="w-full lg:w-1/2">
          <img src="./images/Tharun.webp" alt="GK Tharun Enclave" className="rounded-lg shadow-lg w-full"  lodaing="lazy"/>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="grid grid-cols-3 gap-4 text-lg font-semibold">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(tab.title)}
                className={`py-2 ${activeTab === tab.title ? "text-red-600 border-b-2 border-red-600" : "text-gray-700"}`}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className="p-6 bg-gray-50 rounded-lg mt-4 shadow-sm">
            {tabs.find((tab) => tab.title === activeTab)?.content}
            <div className="mt-6">
              <Link to="/Contact">
                <button className="bg-crimson-600 hover:bg-crimson-800  text-lg font-bold py-2 px-4 rounded-lg w-1/3">
                  Get Quote
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-10 px-6 md:px-12 lg:px-20">
  <div className="max-w-5xl mx-auto">
    {/* Title Section */}
    <div className="text-center mb-12">
      <h2 className="text-3xl md:text-4xl font-bold text-gray-900">Welcome to GK Tharun Enclave , Velachery 🏠</h2>
    </div>

    {/* Introduction */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Discover Elevated Living at GK Tharun Enclave 🌟</h2>
      <p className="text-gray-700 leading-relaxed">
        Tharun Enclave, located in the vibrant neighborhood of Velachery, Chennai, represents the perfect blend of luxury, comfort, and practicality. This premium residential project by GK Promoters offers an unparalleled living experience, designed to cater to the modern homeowner's needs. Whether you are buying your first home or upgrading to a spacious, well-connected residence, Tharun Enclave is the perfect choice.
      </p>
    </section>

    {/* Apartment Options */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Apartment Options 🏡</h2>
      <p className="text-gray-700 leading-relaxed">
        Searching for luxurious flats in Velachery, Chennai? GK Tharun Enclave, a signature project by G K Promoters, redefines premium living with its thoughtfully designed 2 and 3 BHK apartments, crafted to elevate your lifestyle.
      </p>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>2 BHK Apartments: Designed for compact and cozy living without compromising on functionality. 🛋️</li>
        <li>3 BHK Flats: Spacious layouts tailored for families seeking a premium lifestyle. ✨</li>
      </ul>
    </section>

    {/* Why Choose Section */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Why Choose GK Tharun Enclave? 🤔</h2>
      <p className="text-gray-700 leading-relaxed">
        The project combines modern architectural design, a prime location, and a host of world-class amenities, offering a lifestyle that is luxurious, convenient, and elegant.
      </p>
    </section>

    {/* Prime Location Advantages */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Prime Location Advantages 📍</h2>
      <p className="text-gray-700 leading-relaxed">
        Tharun Enclave is strategically located between Velachery and Adambakkam, ensuring excellent connectivity to key parts of Chennai. Its proximity to essential destinations makes it a sought-after address for families and professionals alike.
      </p>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>Velachery 100 Feet Road: Located just 1 km away, providing seamless access to IT parks, shopping centers, and commercial hubs. 🛣️</li>
        <li>Adambakkam Brindavan Nagar Main Road: Only 1 km away, ensuring quick connectivity to surrounding neighborhoods and essential services. 🏙️</li>
      </ul>
    </section>

    {/* Nearby Landmarks */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Nearby Landmarks 🏫🏥🛍️</h2>
      <h3 className="text-xl font-semibold text-gray-700">Proximity to Quality Education 📚</h3>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>DAV Public School, Velachery: Renowned for its strong academic track record and extracurricular activities. 🏫</li>
        <li>San Academy School, Velachery: A modern institution dedicated to holistic development. 🎓</li>
      </ul>
      <h3 className="text-xl font-semibold text-gray-700 mt-6">Reliable Healthcare Nearby 🏥</h3>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>KS Hospital: Known for its specialized care and expert medical professionals. 🏥</li>
        <li>Prime Care Hospital: Offering comprehensive healthcare services. 👨‍⚕️</li>
      </ul>
      <h3 className="text-xl font-semibold text-gray-700 mt-6">Shopping and Entertainment Options 🛍️🎥</h3>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>Phoenix MarketCity: One of the city’s largest malls, offering an array of retail stores, dining options, and entertainment. 🛒</li>
        <li>Grand Square Mall: Ideal for a family outing, featuring movies, shopping, and leisure activities. 🎥</li>
      </ul>
    </section>
    <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Plot Details 📏</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
        The total plot is an East-facing plot measuring 4440 sq.ft., ensuring optimal sunlight and Vastu compliance. This makes Tharun Enclave a perfect choice for those looking to live in balance with nature. 🧭
      </p>
  </section>
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Flats for Sale: What Are Your Options? 🏡</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        GK Tharun Enclave offers a mix of 2 BHK and 3 BHK apartments, designed to suit varying preferences and needs. Each home is thoughtfully crafted to provide ample space, natural light, and ventilation, creating a harmonious living environment. 🌟
      </p>
      <p className="text-gray-700 leading-relaxed mb-4">
        Flat orientations are as follows:
      </p>
  
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="p-4 bg-gray-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800">Flat No. F1/S1 (2 BHK)</h3>
          <p className="text-gray-700 mt-2">916 sq.ft., compact and efficient for cozy living. <strong>North-facing</strong>. UDS: 387 sq.ft. Ideal for small families or professionals. 🏡</p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800">Flat No. F2/S2 (2 BHK)</h3>
          <p className="text-gray-700 mt-2">877 sq.ft., a cozy and practical space. <strong>North-facing</strong>. UDS: 370 sq.ft. Perfect for couples or individuals looking for a comfortable home. 🌟</p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800">Flat No. F3/S3 (2 BHK)</h3>
          <p className="text-gray-700 mt-2">964 sq.ft., a great balance of space and functionality. <strong>East-facing</strong>. UDS: 407 sq.ft. Designed for those seeking a blend of comfort and modern design. 📐</p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800">Flat No. F4/S4 (2 BHK)</h3>
          <p className="text-gray-700 mt-2">974 sq.ft., spacious and thoughtfully designed. <strong>South-facing</strong>. UDS: 411 sq.ft. An excellent choice for families who value space and aesthetics. ✨</p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800">Flat No. T1 (3 BHK)</h3>
          <p className="text-gray-700 mt-2">1113 sq.ft., perfect for families seeking more room. <strong>North-facing</strong>. UDS: 470 sq.ft. Comes with a <strong>private terrace</strong>, ideal for entertaining guests or enjoying serene evenings. 🏠🌿</p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800">Flat No. T2 (2 BHK)</h3>
          <p className="text-gray-700 mt-2">964 sq.ft., combining comfort and efficiency. <strong>East-facing</strong>. UDS: 407 sq.ft. Comes with a <strong>private terrace</strong>, a great feature for enjoying fresh air and open space. 🛋️🌿</p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800">Flat No. T3 (2 BHK)</h3>
          <p className="text-gray-700 mt-2">974 sq.ft., compact yet luxurious. <strong>South-facing</strong>. UDS: 411 sq.ft. Comes with a <strong>private terrace</strong>, perfect for those who love outdoor relaxation. 🏘️🌿</p>
        </div>
      </div>
    </section>

    {/* World-Class Amenities */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">World-Class Amenities 🌟</h2>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>A state-of-the-art gymnasium. 🏋️</li>
        <li>Children’s play area and landscaped gardens. 🌳</li>
        <li>24/7 security with CCTV surveillance. 🛡️</li>
        <li>Covered car parking facilities. 🚗</li>
        <li>Eco-friendly features like rainwater harvesting, energy-efficient lighting, and EV Charging Points. 🌍</li>
      </ul>
    </section>

    {/* Why Tharun Enclave Stands Out */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Why GK Tharun Enclave Stands Out? 🌟</h2>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>Transparent pricing and no hidden costs. 💰</li>
        <li>Backed by decades of trust and excellence in real estate. 🏆</li>
        <li>Dedicated support throughout the home-buying process. 🤝</li>
      </ul>
    </section>
{/* Frequently Asked Questions */}
<section className="mb-10 border-b border-gray-300 pb-6">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Frequently Asked Questions ❓</h2>
  <div className="space-y-4">
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q1: What types of apartments are available at Tharun Enclave?</summary>
      <p className="text-gray-700 mt-2">
        Tharun Enclave offers thoughtfully designed 2BHK and 3BHK apartments, catering to a variety of family sizes and preferences.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q2: What amenities are provided for residents?</summary>
      <p className="text-gray-700 mt-2">
        Residents enjoy state-of-the-art amenities including covered car parking, a children’s play area, landscaped gardens, power backup, and 24/7 security with CCTV surveillance.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q3: Is the location convenient for daily commuting?</summary>
      <p className="text-gray-700 mt-2">
        Absolutely. Tharun Enclave is strategically located between Velachery and Adambakkam, offering excellent connectivity to IT hubs, schools, and transportation facilities.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q4: Is Tharun Enclave suitable for families with children?</summary>
      <p className="text-gray-700 mt-2">
        Yes, it is. The enclave is close to reputed schools and parks, making it a perfect choice for families with children.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q5: How can I schedule a site visit?</summary>
      <p className="text-gray-700 mt-2">
        To schedule a site visit, contact us at <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610-695-122</a> or email <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a>. Our team will be happy to assist you.
      </p>
    </details>
  </div>
</section>


    {/* Contact Section */}
    <section className="mb-10">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Schedule a Visit Today 📅</h2>
      <p className="text-gray-700 leading-relaxed mb-4">Experience the luxury and convenience of Tharun Enclave firsthand. Get in touch with us to book your dream home:</p>
      <ul className="text-gray-700 leading-relaxed">
        <li>📞 Call us: <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610-695-122</a></li>
        <li>📧 Email us: <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a></li>
        <li>🌐 Visit us: <a href="https://www.gkpromoters.com/contact" className="text-blue-600 hover:underline">www.gkpromoters.com/contact</a></li>
      </ul>
    </section>
    <section className="text-left mt-10">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Conclusion 🚪</h2>
  <p className="text-gray-700 leading-relaxed">
    GK Tharun Enclave in Velachery is a premium residential project that combines luxury, convenience, and practicality in one of Chennai’s most sought-after neighborhoods. Whether you're a first-time homebuyer or an experienced investor, these flats offer exceptional value and quality.
  </p>
  <p className="text-gray-700 leading-relaxed mt-4">
    Explore our other projects, including <Link className="text-blue-600 hover:underline" to="/SaiShree"> GK Sai Shree Flats in Puzhuthivakkam </Link>, <Link className="text-blue-600 hover:underline" to="/Riya"> GK Riya Enclave in Adambakkam </Link>, and <Link className="text-blue-600 hover:underline" to="/Santhosh"> GK Santhosh Flats in Madipakkam </Link> to find your perfect dream home today. 🌟
  </p>
</section>

  </div>
</div>





      <Faddmap
        flname="GK Tharun Enclave"
        add1="Plot No 2 & 3"
        add2="AGS Colony 5th Main Road"
        add3="Velachery"
        add4="Chennai - 42"
        mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9071030484984!2d80.20796807587975!3d12.977793214740908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d9f26e44721%3A0x1e9b732f121f0158!2sGK%20Tharun%20Enclave!5e0!3m2!1sen!2sin!4v1711537042837!5m2!1sen!2sin"
      />
    </div>
  );
};

export default Tharun;
