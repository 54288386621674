const currentimg = [
  {
    name: "GK Tharun Enclave",
    loc: "Velachery",
    imgURL: "./images/Tharun.webp",
    buttonURL: "/Tharun",
    approval: "CMDA & RERA approved",
    structure: "Stilt + 5 Floors",
    type: "2BHK/3BHK",
    area: "940 - 1393 sq.ft",
    units: "286",
    possession: "Dec 2026",
    pricePerSqFt: "₹6,500/sq.ft"
  },
  {
    name: "GK Sai Shree Enclave",
    loc: "Puzhuthivakkam",
    imgURL: "./images/Sai Shree.webp",
    buttonURL: "/SaiShree",
    approval: "CMDA approved",
    structure: "Stilt + 3 Floors",
    type: "2BHK/3BHK",
    area: "872 - 1254 sq.ft",
    units: "35",
    possession: "Dec 2025",
    pricePerSqFt: "₹6,200/sq.ft"
  },
  {
    name: "GK Santhosh Flats",
    loc: "Madipakkam",
    imgURL: "./images/Santhosh.webp",
    buttonURL: "/Santhosh",
    approval: "CMDA approved",
    structure: "Stilt + 3 Floors",
    type: "2BHK/3BHK",
    area: "1004 - 1344 sq.ft",
    units: "16",
    possession: "March 2024",
    pricePerSqFt: "₹6,000/sq.ft"
  },
  {
    name: "GK Riya Enclave",
    loc: "Adambakkam",
    imgURL: "./images/Riya.webp", 
    buttonURL: "/Riya", 
    approval: "CMDA approved",
    structure: "Stilt + 3 Floors",
    type: "2BHK/3BHK",
    area: "950 - 1300 sq.ft", 
    units: "20", 
    possession: "June 2025", 
    pricePerSqFt: "₹6,300/sq.ft"
  }
];

export default currentimg;
