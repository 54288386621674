import React from "react";
import currentimg from "./currentimg";
import {Link} from "react-router-dom"

function Current({ name, img, loc, button, description }) {
  return (
    <div className="w-full mt-4 overflow-hidden lg:w-1/4 lg:px-4 flex flex-col items-center">
      <div className="bg-[#CBCBCB] rounded-2xl p-4 flex flex-col justify-between h-full">
        <Link to={button}>
          <img className="rounded-xl mb-4 w-full" src={img} alt={name} lodaing="lazy" />
          <p className="font-bold text-xl text-center text-crimson-500 hover:text-crimson-800">
            {name} <br /> {loc}
          </p>
          <p className="text-black mt-2 text-center">{description}</p>
        </Link>
        <Link
          to={button}
          className="block mt-4 text-center bg-crimson-600 text-black py-2 px-4 rounded-lg hover:bg-crimson-800"
        >
          Explore {name}
        </Link>
      </div>
    </div>
  );
}

export default Current;
