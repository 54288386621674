import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

// Register service worker conditionally
if (process.env.NODE_ENV === 'production') {
  import('./service-worker-registration').then(({ register }) => register());
} else {
  import('./service-worker-registration').then(({ unregister }) => unregister());
}

// Google Tag Manager and Analytics Setup
const tagManagerArgs = {
  gtmId: 'GTM-M2RR25N',
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-214435749-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// Render React App
ReactDOM.render(
  <React.StrictMode>
    <div className="bg-slate-50 font-robotoc text-slate-800">
      <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// Report Web Vitals
reportWebVitals();
