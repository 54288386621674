import React from "react";
function Title({ titles , h1title }) {
  return (
    <div>
      <h2 className=" text-center text-xl lg:text-2xl font-bold  justify-center">

   {titles}
      </h2>
      <h1 className=" text-center text-xl lg:text-2xl font-bold  justify-center">
        {h1title}
     </h1>  </div>
  );
}

export default Title;
