import React from 'react';
import GetQuote from '../Components/getQuote';
import Featured from '../Components/Featured';
import MultipleItems from '../Components/MultipleItems';
import { Smabout } from '../Components/smabout';
import Carousel from '../Components/Banner';
import Rev from './Rev';
import Review from '../Components/Review';
import Testimonials from '../Components/Testimonial';
import { Helmet } from 'react-helmet';
import FAQ from '../Components/Faq';
const Home = () => {
  return (
    <div className='container mx-auto'>
      <Helmet>
        <title>GK Promoters | Leading Flats Promoters in Chennai, We offer affordable 2, 3, and 4 BHK Flats for sale in Madipakkam, Velachery, Puzhuthivakkam, and Adambakkam, setting a new benchmark in real estate excellence</title>
        <meta property="description" content="Find your dream 2BHK & 3BHK flats for sale in Velachery, Madipakkam, Puzhuthivakkam, and Adambakkam. Quality homes await!" />
        <meta property="og:title" content="GK Promoters | Leading Flats Promoters in Chennai, We offer affordable 2, 3, and 4 BHK Flats for sale in Madipakkam, Velachery, Puzhuthivakkam, and Adambakkam, setting a new benchmark in real estate excellence" />
        <meta name="keywords" content="flats in Velachery, 2BHK flat for sale in Madipakkam, 3BHK flat for sale in Puzhuthivakkam, apartments in Adambakkam, GK Promoters" />
        <meta property="og:description" content="Find your dream 2BHK & 3BHK flats for sale in Velachery, Madipakkam, Puzhuthivakkam, and Adambakkam. Quality homes await!" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "RealEstateAgent",
            "name": "GK Promoters",
            "url": "https://www.gkpromoters.com/",
            "logo": "https://www.gkpromoters.com/images/GK_logo1.png",
            "description": "GK Promoters offers premium flats, 2BHK and 3BHK apartments for sale in Velachery, Madipakkam, Puzhuthivakkam, and Adambakkam.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "No.54 B, L.H. Nagar, 1st Cross Street, Adambakkam",
              "addressLocality": "Chennai",
              "addressRegion": "Tamil Nadu",
              "postalCode": "600088",
              "addressCountry": "India"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91-8610695122",
              "contactType": "Customer Service",
              "areaServed": "IN"
            },
            "sameAs": [
              "https://www.facebook.com/gksince1997/",
              "https://x.com/promoters_gk",
              "https://www.linkedin.com/company/105050533/",
              "https://www.instagram.com/gk.promoters22/",
              "https://in.pinterest.com/gkpromoters22/",
              "https://www.youtube.com/@GKPromoters-l3w"
            ]
          })}
        </script>
      </Helmet>
      <GetQuote />
      <br />
      <Featured />
      <br />
      <MultipleItems />
      <br />
      <Smabout />
      <br />
      <Testimonials />
      <FAQ />
      <br />
    </div>
  );
};

export default Home;
