import React, { useState } from "react";
import { FaCar, FaFingerprint ,FaHandHoldingWater } from "react-icons/fa";
import { GiCctvCamera, GiElevator, GiPowerGenerator , GiGardeningShears  } from "react-icons/gi";
import { MdBalcony } from "react-icons/md";
import Faddmap from "../Components/faddmap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const SaiShree = () => {
  const [activeTab, setActiveTab] = useState("About");

  // Location Data
  const locationData = [
    { place: "Sunshine Academy School", distance: "5 mins" },
    { place: "Velachery MRTS Station", distance: "10 mins" },
    { place: "Grand Square Mall", distance: "10 mins" },
    { place: "OMR IT Corridor", distance: "15 mins" },
    { place: "Guindy Industrial Estate", distance: "15 mins" },
    { place: "Chennai International Airport", distance: "20 mins" },
    { place: "Puzhuthivakkam Bus Stand", distance: "5 mins" },
    { place: "Puzhuthivakkam Railway Station", distance: "5 mins" },
    { place: "Vyasa Vidyalaya Matriculation Higher Secondary School", distance: "3 mins" },
  ];
  

  // About Details
  const aboutDetails = {
    units: "286",
    landArea: "1.5 Acres",
    SaleableArea: "940 - 1393 sq.ft",
    approval: "CMDA & RERA approved",
    possessionDate: "December 2026",
    pricePerSqFt: "₹6,500/sq.ft",
  };

  // Amenities Data
  const amenities = [
    { icon: <FaCar />, title: "Covered Car Parking" },
    { icon: <GiElevator />, title: "Lift Facility" },
    { icon: <GiCctvCamera />, title: "CCTV Coverage" },
    { icon: <MdBalcony />, title: "Private Terrace" },
    { icon: <FaHandHoldingWater />, title: "Pure Borewater" },
    { icon: <GiGardeningShears  />, title: "Landscaping" },
   
  ];

  const tabs = [
    { title: "About", content: (
        <>
          <h2 className="text-xl font-bold mb-4">About</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Units:</strong> {aboutDetails.units}</li>
            <li><strong>Land Area:</strong> {aboutDetails.landArea}</li>
            <li><strong>Saleable Area:</strong> {aboutDetails.SaleableArea}</li>
            <li><strong>Approval:</strong> {aboutDetails.approval}</li>
            <li><strong>Possession Date:</strong> {aboutDetails.possessionDate}</li>
            <li><strong>Price per Sq.Ft:</strong> {aboutDetails.pricePerSqFt}</li>
          </ul>
        </>
      )},
    { title: "Features", content: (
        <>
        <h2 className="text-xl font-bold mb-4">Features</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>Strategically located in Puzhuthivakkam with seamless connectivity to key areas like Velachery and OMR.</li>
          <li>Covered car parking ensuring secure and dedicated parking spaces for residents.</li>
          <li>Lift facility for smooth access to all floors, catering to all age groups.</li>
          <li>Comprehensive CCTV coverage for enhanced safety and security.</li>
          <li>Private terraces for select apartments, offering a serene outdoor space for relaxation.</li>
          <li>Reliable pure borewater supply for uninterrupted and clean water access.</li>
          <li>Beautifully landscaped green spaces for aesthetic appeal and leisure.</li>
          <li>Spacious and well-designed 2BHK and 3BHK apartments adhering to Vastu principles.</li>
          <li>Close proximity to reputed schools, healthcare centers, and shopping hubs like Grand Square Mall.</li>
        </ul>
      </>
      
      )},
    { title: "Location", content: (
        <>
          <h2 className="text-xl font-bold mb-4">Location</h2>
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2">Place</th>
                <th className="border border-gray-400 px-4 py-2">Distance</th>
              </tr>
            </thead>
            <tbody>
              {locationData.map((item, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 px-4 py-2">{item.place}</td>
                  <td className="border border-gray-400 px-4 py-2">{item.distance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )},
    { title: "Amenities", content: (
        <>
          <h2 className="text-xl font-bold mb-4">Amenities</h2>
          <ul className="list-disc pl-6 space-y-2">
            {amenities.map((amenity, index) => (
              <li key={index} className="flex items-center space-x-2">
                {amenity.icon}
                <span>{amenity.title}</span>
              </li>
            ))}
          </ul>
        </>
      )},
    { title: "Configuration", content: (
        <>
          <h2 className="text-xl font-bold mb-4">Configuration</h2>
          <table className="bg-white w-full container mx-auto mt-5 rounded-2xl text-slate-900 border-separate shadow-none">
            <thead>
              <tr>
                <th className="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                <th className="bg-crimson-700 text-slate-50 p-2 w-auto">Type</th>
                <th className="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
              </tr>
            </thead>
            <tbody>
              {[
                { flatNo: "G1", type: "2BHK", size: "977" },
                { flatNo: "F1/S1", type: "3BHK", size: "1207" },
                { flatNo: "F2/S2", type: "3BHK", size: "1380" },
                { flatNo: "T1", type: "2BHK", size: "1018" },
                { flatNo: "T2", type: "2BHK", size: "942" },
              ].map((config, index) => (
                <tr key={index} className="bg-slate-100 text-center text-2xl text-slate-900">
                  <td className="p-2">{config.flatNo}</td>
                  <td className="p-2">{config.type}</td>
                  <td className="p-2">{config.size}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )},
  ];

  return (
    <div className="container mx-auto px-4 py-8">
          <Helmet>
        <title>Sai Shree by GK Promoters – Flats for Sale in Puzhuthivakkam, Chennai</title>
    <meta name="title" content="Sai Shree by GK Promoters – Flats for Sale in Puzhuthivakkam, Chennai" />
<meta property="og:title" content="Sai Shree by GK Promoters – Flats for Sale in Puzhuthivakkam, Chennai" />
<meta name="description" content="Discover 2BHK & 3BHK flats For Sale at Sai Shree, Puzhuthivakkam. Vastu-compliant homes with top-notch amenities await you." />
<meta property="og:description" content="Discover 2BHK & 3BHK flats For Sale at Sai Shree, Puzhuthivakkam. Vastu-compliant homes with top-notch amenities await you." />
<meta name="keywords" content="Sai Shree flats, Puzhuthivakkam property For Sale, 2BHK Flats For Sale, 3BHK Flats For Sale, Vastu-compliant apartments, GK Promoters homes, near Puzhuthivakkam railway" />
<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ResidentialBuilding",
    "name": "Sai Shree Flats - Puzhuthivakkam",
    "url": "https://www.gkpromoters.com/SaiShree",
    "description": "Discover Sai Shree Flats by GK Promoters, offering premium 2BHK and 3BHK flats for sale in Puzhuthivakkam with modern amenities.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Puzhuthivakkam",
      "addressRegion": "Chennai",
      "postalCode": "600091",
      "addressCountry": "IN"
    },
    "numberOfRooms": "2-3",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "Starting from ₹80 Lakhs",
      "priceCurrency": "INR"
    }
  })}
</script>

      </Helmet>
      {/* Project Title */}
      <h1 className="text-3xl font-bold text-center mb-6">GK Sai Shree Flats: Premium 2BHK & 3BHK Flats for Sale in Puzhithivakkam, By GK Promoters</h1>

      {/* Layout with Image and Tabs */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Image Section */}
        <div className="w-full lg:w-1/2">
          <img
            src="./images/Sai Shree.webp" // Replace with your image path
            alt="Sai Shree"
            className="rounded-lg shadow-lg w-full"
            lodaing="lazy"
          />
        </div>

        {/* Tabs Section */}
        <div className="w-full lg:w-1/2">
          <div className="grid grid-cols-3 gap-4 text-lg font-semibold">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(tab.title)}
                className={`py-2 ${
                  activeTab === tab.title
                    ? "text-red-600 border-b-2 border-red-600"
                    : "text-gray-700"
                }`}
              >
                {tab.title}
              </button>
            ))}
          </div>

          {/* Tab Content */}
          <div className="p-6 bg-gray-50 rounded-lg mt-4 shadow-sm">
            {tabs.find((tab) => tab.title === activeTab)?.content}

            {/* Get Quote Button (Visible on All Tabs) */}
            <div className="mt-6">
              <Link to="/Contact">
                <button className="bg-crimson-600 hover:bg-crimson-800  text-lg font-bold py-2 px-4 rounded-lg w-1/3">
                  Get Quote
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-8 px-6 md:px-16">
      <div className=" py-10 px-6 md:px-12 lg:px-20">
  <div className="max-w-5xl mx-auto">
    {/* Title Section */}
    <div className="text-left mb-12">
      <h2 className="text-3xl  md:text-4xl font-bold text-gray-900">Welcome to GK Sai Shree Flats, Puzhuthivakkam</h2>
    </div>

    {/* Introduction */}
    <section className="mb-10 border-b pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Introduction</h2>
      <p className="text-gray-700 leading-relaxed">
        Are you searching for your dream home in Chennai? Look no further than <strong>GK Sai Shree Flats</strong>, a premium residential project by G K Promoters. Situated in the bustling locality of Puzhuthivakkam, this development blends elegant design, modern amenities, and a strategic location to create a perfect living experience.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
        Whether you're a first-time homebuyer or an experienced investor, GK Sai Shree offers thoughtfully designed flats for sale that ensure quality, comfort, and value for your investment.
      </p>
    </section>

    {/* Why Choose Section */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Why Choose GK Sai Shree Flats? 🤔</h2>

      <h3 className="text-xl font-semibold text-gray-700 mb-3">Where Is GK Sai Shree Located? 📍</h3>
      <p className="text-gray-700 leading-relaxed">
        Nestled in Puzhuthivakkam, South Chennai, GK Sai Shree boasts a strategic location that offers seamless connectivity and proximity to all essential conveniences.
      </p>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>Schools: Sunshine Academy, Vyasa Vidyalaya, Velammal NewGen. 🎓</li>
        <li>Healthcare: Dr. Kennedy's Saraswathy Multispeciality Hospital, PrimeCare Nursing Home. 🏥</li>
        <li>Shopping & Entertainment: Grand Square Mall, Phoenix MarketCity. 🛍️</li>
      </ul>
      <p className="text-gray-700 leading-relaxed mt-4">
        Additionally, the project is well-connected to Velachery MRTS, Chennai International Airport, and major roads like GST Road and the East Coast Road (ECR). This makes commuting hassle-free and ensures you're never far from scenic weekend getaways. 🚗
      </p>
    </section>

    {/* Amenities */}
    <section className="mb-10 border-b border-gray-300 pb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">What Amenities Does GK Sai Shree Offer? 🏢</h2>
      <ul className="list-disc list-inside text-gray-700 mt-4">
        <li>Covered Car Parking: Secure and dedicated parking spaces. 🚗</li>
        <li>Private Terrace: Serene outdoor spaces for relaxation. 🌿</li>
        <li>CCTV Surveillance: Enhanced security for peace of mind. 🛡️</li>
        <li>Lift Facility: Easy access to all floors. 🛗</li>
        <li>Landscaping: Green spaces designed for aesthetics and leisure. 🌳</li>
      </ul>
    </section>
    <section className="mb-10 border-b border-gray-300 pb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Plot Details 📏</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
    The total plot is a West-facing plot measuring 3318 sq.ft., ensuring optimal land utilization and excellent connectivity. This makes GK Sai Shree a prime choice for homeowners seeking a well-connected lifestyle. 🧭
  </p>
  </section>
    {/* Flats for Sale */}
    <section className="mb-10 border-b border-gray-300 pb-6">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Flats for Sale: What Are Your Options? 🏡</h2>
  <p className="text-gray-700 leading-relaxed mb-4">
    GK Sai Shree offers a mix of 2 BHK and 3 BHK apartments, designed to suit varying preferences and needs. Each home is thoughtfully crafted to provide ample space, natural light, and ventilation, creating a harmonious living environment. 🌟
  </p>

  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div className="p-4 bg-gray-100 rounded-lg shadow">
      <h3 className="text-lg font-semibold text-gray-800">Flat No. G1 (2 BHK)</h3>
      <p className="text-gray-700 mt-2">977 sq.ft., spacious and functional. <strong>South-facing</strong>. UDS: 400 sq.ft. Perfect for small families or professionals. 🏡</p>
    </div>
    <div className="p-4 bg-gray-100 rounded-lg shadow">
      <h3 className="text-lg font-semibold text-gray-800">Flat No. F1/S1 (3 BHK)</h3>
      <p className="text-gray-700 mt-2">1207 sq.ft., ideal for growing families. <strong>East-facing</strong>. UDS: 495 sq.ft. Designed for a luxurious lifestyle. ✨</p>
    </div>
    <div className="p-4 bg-gray-100 rounded-lg shadow">
      <h3 className="text-lg font-semibold text-gray-800">Flat No. F2/S2 (3 BHK)</h3>
      <p className="text-gray-700 mt-2">1380 sq.ft., perfect for larger families. <strong>North-facing</strong>. UDS: 566 sq.ft. Combines elegance and practicality. 🏠</p>
    </div>
    <div className="p-4 bg-gray-100 rounded-lg shadow">
      <h3 className="text-lg font-semibold text-gray-800">Flat No. T1 (2 BHK)</h3>
      <p className="text-gray-700 mt-2">1018 sq.ft., a balanced design for comfort. <strong>East-facing With Private Terrace</strong>. UDS: 413 sq.ft. Suitable for small families. 📐</p>
    </div>
    <div className="p-4 bg-gray-100 rounded-lg shadow">
      <h3 className="text-lg font-semibold text-gray-800">Flat No. T2 (2 BHK)</h3>
      <p className="text-gray-700 mt-2">942 sq.ft., compact yet practical. <strong>North-facing with Private Terrace</strong> . UDS: 383 sq.ft. Ideal for cozy living. 🌟</p>
    </div>
  </div>
</section>

{/* Frequently Asked Questions */}
<section className="mb-10 border-b border-gray-300 pb-6">
  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Frequently Asked Questions ❓</h2>
  <div className="space-y-4">
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q1: What types of apartments are available at GK Sai Shree Flats?</summary>
      <p className="text-gray-700 mt-2">
        GK Sai Shree Flats offers a mix of 2BHK apartments ranging from 942 sq.ft. to 1,018 sq.ft. and spacious 3BHK apartments up to 1,380 sq.ft., catering to various family sizes and needs.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q2: What amenities are available for residents?</summary>
      <p className="text-gray-700 mt-2">
        Residents enjoy covered car parking, private terraces, lift facilities, CCTV surveillance, and landscaped gardens designed for relaxation and aesthetics.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q3: Is GK Sai Shree Flats in a prime location?</summary>
      <p className="text-gray-700 mt-2">
        Yes, GK Sai Shree Flats is located in Puzhuthivakkam, offering seamless connectivity to Velachery MRTS, GST Road, and Chennai International Airport, along with proximity to schools, hospitals, and shopping centers.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q4: Are there good schools and hospitals nearby?</summary>
      <p className="text-gray-700 mt-2">
        Absolutely. Nearby schools include Sunshine Academy and Vyasa Vidyalaya. Healthcare facilities like Dr. Kennedy's Saraswathy Multispeciality Hospital and Prime Care Nursing Home are also within close reach.
      </p>
    </details>
    <details className="bg-gray-100 p-4 rounded-lg shadow">
      <summary className="font-semibold text-gray-800 cursor-pointer">Q5: How can I schedule a site visit?</summary>
      <p className="text-gray-700 mt-2">
        To schedule a site visit, contact us at <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610-695-122</a> or email <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a>. Our team will be happy to assist you.
      </p>
    </details>
  </div>
</section>
    {/* Contact Section */}
    <section className="mb-10">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">How to Book Your Dream Flat 📞</h2>
      <p className="text-gray-700 leading-relaxed mb-4">Booking your dream home is simple! Contact G K Promoters today:</p>
      <ul className="text-gray-700 leading-relaxed">
        <li>Phone: <a href="tel:+918610695122" className="text-blue-600 hover:underline">+91-8610695122</a> 📲</li>
        <li>Email: <a href="mailto:info@gkpromoters.com" className="text-blue-600 hover:underline">info@gkpromoters.com</a> 📧</li>
        <li>Website: <a href="https://www.gkpromoters.com" className="text-blue-600 hover:underline">Visit our Contact Page</a> 🌐</li>
      </ul>
    </section>

    {/* Conclusion */}
    <section className="text-left mt-10">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Conclusion 🚪</h2>
      <p className="text-gray-700 leading-relaxed">
        GK Sai Shree Flats in Puzhuthivakkam is more than just a residential project; it’s a gateway to modern living in one of Chennai’s most promising neighborhoods. Whether you're a first-time buyer, a growing family, or an investor, these flats for sale are tailored to meet your needs.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
        Explore our other projects, including <Link className="text-blue-600 hover:underline"  to="/Tharun" >  GK Tharun Enclave in Velachery </Link>,<Link className="text-blue-600 hover:underline"  to="/Riya" >  GK Riya Enclave in Adambakkam </Link> and <Link className="text-blue-600 hover:underline"  to="/Santhosh" >  GK Santhosh Flats in Madipakkam </Link> and  take the first step toward your dream lifestyle today. 🌟
      </p>

    </section>
  </div>
</div>

    </div>

      {/* Address and Map Section */}
      <Faddmap flname="Sai Shree" 
      add1="Plot No 1B & 2B ," 
      add2="Elumalai Nagar , Annai Tersa Nagar 9th street" add3="Puzuthivakkam" add4="Chennai-91"  
       mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d846.1829969458854!2d80.20223902836955!3d12.96956659600167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d00780ef0f1%3A0x62c5bcff7797ff1c!2sGK%20Sri%20Sai%20Riya%20Enclave!5e0!3m2!1sen!2sin!4v1716530962069!5m2!1sen!2sin" />
    </div>
  );
};

export default SaiShree;
