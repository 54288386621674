import React from 'react';
import  Preflats from '../Components/Preflats';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Thiara = () => {
  return <div className='container mx-auto'>
      <Title titles=" Thiara Enclave - Madipakkam" />
      <Preflats imgsrc="./images/Thiara-Bageerathi-Nagar.webp" btnurl="/thiara" near1="Near Hotel Nakshtra" near2="Near Pon Vidhyasram School" btn={true} />

<br />

<Faddmap add1="Plot No.82 & 83," add2="Vallal Adhiyaman Street,"   add3="Bageerathi Nagar" add4="Madipakkam" add5="Chennai-91" near1="Near Pon Vidhyasram School"  mapurl="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.110926933467!2d80.21135151526032!3d12.964753118535379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d4d0f600555%3A0xe8c1e8be9602e9a7!2sThiara%20Flats!5e0!3m2!1sen!2sin!4v1643048359550!5m2!1sen!2sin" />
  <br />
  </div>



};

export default Thiara;
