import React, { useState } from "react";
import { Link } from "react-router-dom";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-xl border-gray-800 p-2">
      <button
        className="flex justify-between items-center w-full text-left focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <span className="text-lg font-medium text-gray-900">{question}</span>
        <svg
          className={`w-6 h-6 transition-transform ${
            isOpen ? "transform rotate-180" : ""
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="mt-2 text-gray-600">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question:
        "What sets GK Promoters apart from other real estate developers in Chennai?",
      answer:
        "At GK Promoters, we prioritize transparency, customer satisfaction, and affordability. Our commitment to quality, timely delivery, and tailored customer support has made us a trusted name in Chennai's real estate market since 1997.",
    },
    {
      question: "How does GK Promoters ensure transparency in transactions?",
      answer:
        "We provide clear documentation, detailed agreements, and open communication throughout the buying process. Our team ensures buyers fully understand every aspect of their investment.",
    },
    {
      question:
        "How do you select locations for new projects like those in Velachery and Madipakkam?",
      answer:
        "We carefully evaluate connectivity, neighborhood development, and future growth potential to ensure our projects are situated in prime, high-demand areas.",
    },
    {
      question:
        "What unique amenities are available in projects like Tharun Enclave or Riya Enclave?",
      answer: (
        <>
          Our projects offer amenities like 24/7 security, reserved parking,
          modern elevators, landscaped gardens, and proximity to schools,
          hospitals, and shopping centers. Learn more about{" "}
          <Link to="/Tharun" className="text-crimson-500 hover:underline">
            Tharun Enclave
          </Link>{" "}
          and{" "}
          <Link to="/Riya" className="text-crimson-500 hover:underline">
            Riya Enclave
          </Link>
          .
        </>
      ),
    },
    {
      question:
        "What sustainable features are incorporated in GK Promoters' projects?",
      answer:
        "We integrate eco-friendly features like rainwater harvesting, solar panels, and energy-efficient lighting in our projects to promote sustainable living.",
    },
    {
      question: "How does GK Promoters assist NRI investors?",
      answer:
        "We offer specialized services for NRI investors, including virtual property tours, documentation assistance, and property management services.",
    },
    {
      question:
        "Can I visit ongoing construction sites for projects like Santhosh Flats or Sai Shree Flats?",
      answer: (
        <>
          Yes, we encourage buyers to visit our construction sites. It's part of
          our transparent process to ensure you're updated on progress. Schedule
          a visit to{" "}
          <Link to="/santhosh" className="text-crimson-500 hover:underline">
            Santhosh Flats
          </Link>{" "}
          or{" "}
          <Link to="/SaiShree" className="text-crimson-500 hover:underline">
            Sai Shree Flats
          </Link>
          .
        </>
      ),
    },
    {
      question: "What after-sales services does GK Promoters provide?",
      answer:
        "We offer comprehensive after-sales support, including maintenance services, property management, and resale assistance for our valued customers.",
    },
    {
      question: "How can I stay updated on new GK Promoters projects and offers?",
      answer:
        "You can subscribe to our newsletter, follow us on social media, or regularly check our website for updates on new projects and special offers.",
    },
  ];

  return (
    <div className=" container max-w-4xl rounded-lg p-2 bg-slate-100 mx-auto ">
      <h2 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">
        Frequently Asked Questions
      </h2>
      <p className="text-center text-gray-600 mb-8">
        Have questions? Here are some of the most frequently asked questions
        about GK Promoters and our projects. If you have further inquiries, feel
        free to{" "}
        <Link to="/contact" className="text-crimson-500 hover:underline">
          contact us
        </Link>
        .
      </p>
      <div className="space-y-6">
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
