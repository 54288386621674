import React from "react";
import Modal from "./popup";

function GetQuote() {
  return (
    <div className="mb-2 ">
      <div className=" rounded-2xl   bg-white px-3 mx-auto flex flex-wrap ">
      <div class="w-full lg:pr-2 pb-2  xl:w-3/5">
          <video
            autoPlay
            loop
            muted
            className="  lg:w-full rounded-2xl object-fill z-50"
            src="./video/background1.mp4"
          />
        </div>
        <div className="w-full xl:w-2/5 md:w-full justify-center lg:items-start rounded-xl mb-2 bg-[#CBCBCB] text-center lg:text-left">
          <img
            className="   rounded-t-xl  "
            src="./images/background.webp"
            alt="background"
          />
          <h1 className="  lg:mx-3 text-center font-bold  text-2xl lg:text-2xl  tracking-loose w-full">
            GK Promoters 
          </h1>
          <p className="leading-normal lg:mx-3 text-center font-semibold text-xl ">Crafting Dream Dwellings You’ll Love. </p>
          <br />
          <p className=" lg:mx-3  text-xl text-center lg:text-xl tracking-loose w-full">
           We offer affordable 2, 3, and 4 BHK Flats in Madipakkam, Velachery, Puzhuthivakkam, and Adambakkam, 
          </p>
     

          <p className="leading-normal lg:mx-3 text-center  text-xl "> setting a new benchmark in real estate excellence. </p>
          
       <div className="items-center text-center container mx-auto text-black rounded-xl px-2 mb-2  ">  <Modal  btnclass=" text-slate-50 bg-crimson-600 hover:bg-crimson-800 text-lg  py-2 px-4 rounded-lg" /></div>
          
        </div>

      </div>
    </div>
  );
}

export default GetQuote;
