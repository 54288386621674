import React from "react";
import Slider from "react-slick";
import Title from "./title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function MultipleItems() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-slate-100 rounded-xl py-6">
      {/* Section Title */}
      <div className="text-center">
        <Title titles="Banking Partners" />
        <p className="text-gray-600 mt-2 max-w-3xl mx-auto">
          We collaborate with top financial institutions to provide seamless home loan solutions for our buyers. Our trusted partners include HDFC, ICICI, LIC Housing Finance, and SBI.
        </p>
      </div>
      <br />

      {/* Banking Partners Slider */}
      <Slider {...settings}>
        <div className="text-center">
          <img
            className="mx-auto rounded-2xl w-52"
            src="./images/hdfc.png"
            alt="HDFC Bank - Trusted Home Loan Partner"
          />
          <p className="text-gray-700 mt-2 text-sm">
            HDFC Bank offers competitive home loan rates to help you own your dream home.
          </p>
        </div>
        <div className="text-center">
          <img
            className="mx-auto rounded-2xl w-52"
            src="./images/icici.png"
            alt="ICICI Bank - Home Loan Provider"
          />
          <p className="text-gray-700 mt-2 text-sm">
            ICICI Bank ensures quick loan processing and tailored financial solutions.
          </p>
        </div>
        <div className="text-center">
          <img
            className="mx-auto rounded-2xl w-52"
            src="./images/lic.png"
            alt="LIC Housing Finance - Reliable Home Loan Partner"
          />
          <p className="text-gray-700 mt-2 text-sm">
            LIC Housing Finance supports homebuyers with flexible and low-interest loans.
          </p>
        </div>
        <div className="text-center">
          <img
            className="mx-auto rounded-2xl w-52"
            src="./images/sbi.png"
            alt="SBI Home Loans - Trusted Financial Partner"
          />
          <p className="text-gray-700 mt-2 text-sm">
            SBI Home Loans provides trustworthy financing options for homebuyers.
          </p>
        </div>
      </Slider>
    </div>
  );
}
