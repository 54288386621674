import React from "react";
import { Tabs, Tab } from "../Components/specs/Specs";
// import Structure from "../Components/specs/Structure";
// import Kitchen from "../Components/specs/Kitchen";
// import Wall from "../Components/specs/Wall";
// import Bathroom from "../Components/specs/Bathroom";
// import Tiles from "../Components/specs/Tiles";
// import Electrical from "../Components/specs/Electrical";
// import Staircase from "../Components/specs/Staircase";
// import Balcony from "../Components/specs/Balcony";
// import Doors from "../Components/specs/Doors";
// import Locks from "../Components/specs/Locks";
// import Windows from "../Components/specs/Windows";
// import Shelves from "../Components/specs/Shelves";
// import Painting from "../Components/specs/Painting";
// import Terrace from "../Components/specs/Terrace";
import Title from "../Components/title";
import MaterialsTab from "../Components/MaterialsTab";
import Mat from "../Components/Mat";
import { Helmet } from 'react-helmet'
export default function Specification() {
  return (
    <div>
      <Helmet>
        <title>GK Promoters - Superior Specifications</title>
      <meta name="title" content="GK Promoters - Superior Specifications" />
<meta property="og:title" content="GK Promoters - Superior Specifications" />
<meta name="description" content="Discover superior construction features: RCC structure, UPVC windows, high-quality interiors, and modern fittings." />
<meta property="og:description" content="Discover superior construction features: RCC structure, UPVC windows, high-quality interiors, and modern fittings." />
<meta name="keywords" content="construction specifications Chennai, RCC structure flats, quality materials, premium interior fittings, GK Promoters standards" />
<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Specifications - GK Promoters",
    "url": "https://www.gkpromoters.com/specifications",
    "description": "Explore the specifications of our 2BHK and 3BHK flats, including high-quality materials, modern architecture, and premium finishes.",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        { "@type": "ListItem", "position": 1, "name": "Home", "item": "https://www.gkpromoters.com/" },
        { "@type": "ListItem", "position": 2, "name": "Specifications", "item": "https://www.gkpromoters.com/Specifications" }
      ]
    }
  })}
</script>

      </Helmet>
<Title h1title="Specification" />, 
    <MaterialsTab />
    <Mat />
    </div>
  );
}
