import React from 'react';
import Title from './title';

export const Smabout = () => {
  return (
    <div className="container mt-10 rounded-lg mx-auto bg-slate-100">
      <Title titles="Why Choose us" />
      <div className="flex flex-wrap justify-center p-2">
        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/trust.png"
            alt="Experience"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Experience and Trust in Real Estate</h3>
          <p className="text-base font-light leading-6">
            With over 20 years of expertise in Chennai's real estate market, we've built a reputation for reliability and excellence. Our commitment to delivering creative and customized solutions has made us a trusted partner for homebuyers in Velachery, Adambakkam, and beyond.
          </p>
        </div>

        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/team.png"
            alt="Team"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Expert Team of Real Estate Developers</h3>
          <p className="text-base font-light leading-6">
            Our team comprises highly skilled professionals with deep industry knowledge and hands-on experience in real estate. We stay updated with the latest market trends and technologies to deliver modern homes that meet and exceed your expectations.
          </p>
        </div>

        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/quality.png"
            alt="Product"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Premium Flats with In-House Quality Assurance</h3>
          <p className="text-base font-light leading-6">
            At GK Promoters, we maintain strict quality assurance procedures to ensure that every home meets the highest standards. From design to delivery, our in-house manufacturing capabilities guarantee durability and premium finishes for your property.
          </p>
        </div>

        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/personal.png"
            alt="Personalized"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Tailored Solutions for Every Buyer</h3>
          <p className="text-base font-light leading-6">
            We understand that every homebuyer has unique needs and preferences. That's why we offer personalized solutions, crafting homes in Velachery, Adambakkam, Puzhuthivakkam, and Madipakkam that perfectly suit your lifestyle and budget.
          </p>
        </div>

        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/punctuality.png"
            alt="Punctuality"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Value for Money on Every Purchase</h3>
          <p className="text-base font-light leading-6">
            Your time and investment are important to us, which is why we offer competitive pricing without compromising on quality. Our streamlined processes and cost-effective strategies ensure you get the best returns on your investment.
          </p>
        </div>

        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/client.png"
            alt="Satisfaction"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Customer-Centric Satisfaction Guaranteed</h3>
          <p className="text-base font-light leading-6">
            At GK Promoters, your satisfaction is our top priority. We measure our success through positive feedback and strive to make every step of your home-buying journey a smooth and rewarding experience.
          </p>
        </div>

        {/* Additional Content */}
        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/Innovation.png"
            alt="Innovation"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Innovative Designs for Modern Living</h3>
          <p className="text-base font-light leading-6">
            At GK Promoters, we integrate innovative designs and smart home features into our projects to create living spaces that are both functional and aesthetically pleasing, meeting the needs of today's homeowners.
          </p>
        </div>

        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/Support.png"
            alt="Support"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Comprehensive Post-Sales Support</h3>
          <p className="text-base font-light leading-6">
            Our commitment to our customers doesn't end at the point of sale. We provide extensive post-sales support, including property maintenance and assistance with documentation, ensuring a worry-free ownership experience.
          </p>
        </div>

        <div className="text-center mx-4 my-8 w-full lg:w-1/4">
          <img
            src="./images/smabout/Green.png"
            alt="Green Living"
            className="mx-auto mb-4"
          />
          <h3 className="font-semibold">Eco-Friendly and Sustainable Living</h3>
          <p className="text-base font-light leading-6">
            We prioritize sustainability in our projects by incorporating features like rainwater harvesting, solar energy systems, and green spaces, helping you embrace an eco-friendly lifestyle.
          </p>
        </div>
      </div>
    </div>
  );
};
