// BlogPost.js
import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ title, date, summary, link, image }) => {
  return (
    <Link 
      to={link} 
      className="block bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div 
        className="h-48 w-full bg-cover bg-center rounded-t-lg" 
        style={{ backgroundImage: `url(${image})` }}
      >
      </div>
      <div className="p-4">
        <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
        <p className="text-sm text-gray-500 mt-2">by admin | {date} | GK Promoters</p>
        <p className="text-gray-600 text-sm mt-3">{summary}</p>
      </div>
    </Link>
  );
};

export default BlogPost;
