import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Error = () => {
  return (
    <div>
      <Helmet>
        <title>404 - Page Not Found | G K Promoters</title>
        <meta name="description" content="Oops! The page you're looking for doesn't exist. Go back to the homepage to explore more." />
      </Helmet>
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="px-4 lg:py-12">
          <div className="lg:gap-4 lg:flex">
            <div className="flex flex-col items-center justify-center md:py-24 lg:py-32">
              <h1 className="font-bold text-blue-600 text-9xl">404</h1>
              <p className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                <span className="text-red-500">Oops! Page not found </span>
              </p>
              <p className="mb-8 text-center text-blue-500 md:text-lg">
                The page you’re looking for doesn’t exist.
              </p>
              <Link to="/">
                <button className="bg-blue-500 hover:bg-blue-700 text-white text-xl px-3 py-2 rounded-full">
                  Go Home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
