import React from "react";
import { FaHome, FaPhone, FaEnvelope } from "react-icons/fa";
import Title from "./title";
import { HomeIcon, PhoneIcon, MailOpenIcon } from "@heroicons/react/solid";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaPinterestP,
  FaYoutube,
} from "react-icons/fa";

const Faddmap = ({ flname, add1, add2, add3, add4, add5, mapurl }) => {
  return (
    <div>
      <Title titles="Address and location" />
      <br />
      <div className="rounded-2xl container mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 border-4 border-gray-800 rounded-2xl">
            <div>
              <h1 className="text-2xl text-center font-extrabold border-gray-400 text-gray-800">
                Address
              </h1>
              <br />
            </div>

            <div>
              {/* Address */}
              <p className="flex items-start px-6 lg:px-10 mt-2 mb-2 mx-2">
                <HomeIcon className="h-6 w-6 lg:h-8 lg:w-8" />
                <span className="text-sm lg:text-xl text-left mx-2">
                  {add1}
                  <br />
                  {add2}
                  <br />
                  {add3}
                  <br />
                  {add4}
                  <br />
                  {add5}
                </span>
              </p>

              {/* Email */}
              <p className="flex items-center px-6 lg:px-10 mb-4 mx-2">
                <MailOpenIcon className="h-6 w-6 lg:h-7 lg:w-7" />
                <a href="mailto:info@gkpromoters.com" className="ml-2">
                  <span className="text-sm lg:text-xl">
                    info@gkpromoters.com
                  </span>
                </a>
              </p>

              {/* Phone Numbers */}
              <p className="flex items-center px-6 lg:px-10 mx-2 mb-4 space-x-4">
                <PhoneIcon className="h-6 w-6 lg:h-8 lg:w-8" />
                <span className="text-sm lg:text-xl">
                  <a href="tel:+918939764764">+91-8939764764</a>
                </span>
                <span className="text-sm lg:text-xl">
                  <a href="tel:+918610695122">| +91-8610695122</a>
                </span>
              </p>

              {/* Social Media Icons */}
              <p className="flex flex-wrap items-center justify-center px-6 lg:px-10 mt-4 mb-4 mx-2">
                <a
                  href="https://www.facebook.com/gksince1997/"
                  className="mr-4 text-blue-600"
                >
                  <FaFacebookF className="h-6 w-6 sm:h-8 sm:w-8" />
                </a>
                <a
                  href="https://x.com/promoters_gk"
                  className="mr-4 text-blue-400"
                >
                  <FaTwitter className="h-6 w-6 sm:h-8 sm:w-8" />
                </a>
                <a
                  href="https://www.instagram.com/gk.promoters22/"
                  className="mr-4 text-purple-500"
                >
                  <FaInstagram className="h-6 w-6 sm:h-8 sm:w-8" />
                </a>
                <a
                  href="https://www.linkedin.com/company/105050533/"
                  className="mr-4 text-blue-700"
                >
                  <FaLinkedinIn className="h-6 w-6 sm:h-8 sm:w-8" />
                </a>
                <a
                  href="https://in.pinterest.com/gkpromoters22/"
                  className="mr-4"
                >
                  <FaPinterestP
                    className="h-6 w-6 sm:h-8 sm:w-8"
                    style={{ color: "#E60023" }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/@GKPromoters-l3w"
                  className="mr-4"
                >
                  <FaYoutube
                    className="h-6 w-6 sm:h-8 sm:w-8"
                    style={{ color: "#FF0000" }}
                  />
                </a>
              </p>
            </div>
          </div>
          {/* Map Section */}
          <div className="w-full lg:w-1/2 bg-blue-300 rounded-2xl">
            <div>
              <iframe
                className="rounded-2xl"
                width="100%"
                height={425}
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                src={mapurl}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faddmap;
