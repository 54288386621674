import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Akrthi = () => {
  return <div>
      <Title titles="Akrthi Flats - Velachery" />
      <br />
      <Flat imgsrc="./images/Akrthi-Padmavati-Nagar.webp" btnurl="/Contact" />

        <br />
<Faddmap add1="Plot No.16," add2="Padmavati Nagar"   add3="3rd Street," add4="Velachery," add5="Chennai-42" near1="Near San Acadamey School"  mapurl="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.955031862361!2d80.20998461526042!3d12.97472801831908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525dc9c7ed5b2f%3A0x8a59bfa395fbb6f7!2sGK%20Promoters%20-%20Akriti%20Flats!5e0!3m2!1sen!2sin!4v1642871574272!5m2!1sen!2sin" />
  <br />
  </div>


};

export default Akrthi;
