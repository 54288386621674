import React from 'react';
import currentimg from '../Components/currentimg';
import Currentpjsx from '../Components/Currentpjsx';
import { Helmet } from 'react-helmet';
const CurrentProjects = () => {
  return (
    <div className="container mx-auto px-4 py-8">
        <Helmet>
          <title>Completed Projects by GK Promoters</title>
          <meta name="title" content="Ongoing Projects in Chennai by GK Promoters " />
<meta property="og:title" content="Completed Projects by GK Promoters" />
<meta name="description" content="Explore ongoing residential  projects  in  Chennai Velachery, Madipakkam, and other prime locations. Your dream home is near!." />
<meta property="og:description" content="Check out our portfolio of completed projects featuring modern homes across Chennai’s prime locations." />
<meta name="keywords" content="current projects Chennai, ongoing flats Velachery, under-construction properties Madipakkam, GK Promoters latest projects" />
<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "Current Projects - GK Promoters",
    "url": "https://www.gkpromoters.com/CurrentProjects",
    "description": "Explore GK Promoters' current projects, featuring 2BHK and 3BHK flats for sale in Velachery, Madipakkam, Puzhuthivakkam, and Adambakkam.",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        { "@type": "ListItem", "position": 1, "name": "Home", "item": "https://www.gkpromoters.com/" },
        { "@type": "ListItem", "position": 2, "name": "Current Projects", "item": "https://www.gkpromoters.com/CurrentProjects" }
      ]
    }
  })}
</script>

      </Helmet>
      <h1 className="text-3xl font-bold text-center mb-6">Current Projects in Chennai by GK Promoters</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {currentimg.map((project) => (
          <Currentpjsx
            key={project.name}
            name={project.name}
            img={project.imgURL}
            loc={project.loc}
            approval={project.approval}
            structure={project.structure}
            type={project.type}
            area={project.area}
            units={project.units}
            possession={project.possession}
            titlea={project.titlea}
            buttonURL={project.buttonURL} // Navigation link
          />
        ))}
      </div>
    </div>
  );
};

export default CurrentProjects;
