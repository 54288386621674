import React from 'react';
import Flat from '../Components/flat';

import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisaitharun = () => {
  return <div>
      <Title titles="Sri Sai Tharun Enclave - Velachery" />
      <br />
      <Flat imgsrc="./images/Sri Sai Tharun - VOC Nagar.webp" btnurl="/Srisaitharun" near2="Near San Acadmey School" near1="Near Velachery Railway Station" btn={false}/>
<br/>
<Title titles="Configuration" />
<table class="bg-white w-full container mx-auto mt-5 rounded-2xl text-gray-900 border-separate shadow-none">
            <thead>
                <tr class="">
                <th class="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                    <th class="bg-crimson-700  text-slate-50 p-2 w-auto">Type</th>
                    <th class="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
                </tr>
            </thead>
            <tbody>
            <tr class="bg-slate-100 text-2xl text-center text-green-900">
                    <td class="p-2 ">F1/S1/T1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2">726</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-green-900">
                    <td class="p-2 rounded-bl-2xl">F2/S2/T2</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2 rounded-br-2xl ">704</td>
                </tr>
            </tbody>
        </table>
        <br/>
<Faddmap add1="Plot No.16," add2="VOC Nagar,"  add3="1st Street," add4="Velachery" add5="Chennai-42" near1="Near San Acadmey School"    mapurl="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9502460429135!2d80.20968191526038!3d12.975034118312577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d24ad048971%3A0x781124ccfd5108b8!2sSri%20Sai%20Tharun%20Flats!5e0!3m2!1sen!2sin!4v1643048185962!5m2!1sen!2sin" />
  </div>


};

export default Srisaitharun;
