import React from 'react';
import ContactUs from '../Components/Form';
import { HomeIcon } from "@heroicons/react/solid";
import { PhoneIcon } from "@heroicons/react/solid";
import { MailOpenIcon } from "@heroicons/react/solid";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter} from "react-icons/fa";
import { Helmet } from 'react-helmet'
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Contact = () => {
  return <div>
    <Helmet>
      <title>Contact GK Promoters</title>
    <meta name="title" content="Contact GK Promoters" />
<meta property="og:title" content="Contact GK Promoters" />
<meta name="description" content="Reach out to GK Promoters to inquire about flats in Velachery, Madipakkam, Puzhuthivakkam, and Adambakkam." />
<meta property="og:description" content="Reach out to GK Promoters to inquire about flats in Velachery, Madipakkam, Puzhuthivakkam, and Adambakkam." />
<meta name="keywords" content="contact GK Promoters, flat inquiries Velachery, property consultation Chennai, affordable flats Madipakkam" />
<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contact Us - GK Promoters",
    "url": "https://www.gkpromoters.com/contact",
    "description": "Get in touch with GK Promoters for inquiries about 2BHK and 3BHK flats for sale in Chennai.",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91-8610695122",
      "contactType": "Customer Service",
      "areaServed": "IN",
      "availableLanguage": ["English", "Tamil"]
    },
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        { "@type": "ListItem", "position": 1, "name": "Home", "item": "https://www.gkpromoters.com/" },
        { "@type": "ListItem", "position": 2, "name": "Contact", "item": "https://www.gkpromoters.com/Contact" }
      ]
    }
  })}
</script>

    </Helmet>
           <div class="container mx-auto">
            <div class="">
            <Title h1title="Contact Us" />
                <p class="lg:w-1/2 text-center text-xl mx-auto mt-5">
  For Enquries and Quotation Please Contact Us Using the form below!😀</p>
            </div>
            <div className='flex  items-center content-center'>
<img class="hidden lg:flex w-1/2 " src='/images/contactus.webp'></img>
            <div class="mt-7 p-2 lg:w-1/2  mx-auto">
                <ContactUs />
                </div>
                </div>

            </div>
            <div className=" rounded-2xl container mx-auto  ">

  <Faddmap
        flname="GK Promoters"
        add1="No.54B,"
        add2="L.H.Nagar 1st Cross Street,"
        add3="Adambakkam"
        add4="Chennai - 88"
        mapurl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d408.6515813353165!2d80.20699693635189!3d12.986497087761514!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda6021ce17413e01!2sgk%20promoters!5e0!3m2!1sen!2sin!4v1645003216319!5m2!1sen!2sin"
      />
</div>

  </div>
};

export default Contact;
