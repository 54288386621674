import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisaishanmuga = () => {
  return <div>
      <Title titles="Sri Sai Shanmuga Enclave - Adambakkam" />
      <br />
      <Flat imgsrc="./images/Completed Projects/Sri Sai Shanmuga.webp" btn={true}  />


<Faddmap add1="plot No.25A," add2="Andal Nagar," add3="1st Main Road,"  add4="Adambakkam" add5="chennai-88"  mapurl="
https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15551.43801084408!2d80.20851!3d12.9808367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2a8b9d7e627dea34!2sSri%20Sai%20Shanmuga!5e0!3m2!1sen!2sin!4v1643047052446!5m2!1sen!2sin" />
  <br />
  </div>


};

export default Srisaishanmuga;
