import React from 'react';
import { Link } from 'react-router-dom'; // Ensure you have react-router-dom installed
import { Helmet } from 'react-helmet'; // Ensure you have react-helmet installed

const Trends2025 = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <Helmet>
        <title>Top 5 Trends in Real Estate Development for 2025</title>
        <meta name="description" content="Discover the top trends shaping the future of real estate development in 2025, including sustainable building practices and smart home technology." />
        <meta name="keywords" content="2BHK flats for sale, 3BHK flats for sale, real estate trends, sustainable building practices, smart homes, urban living, Chennai real estate" />
      </Helmet>

      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">Top 5 Trends in Real Estate Development for 2025</h1>

          <div className="space-y-6">
            <div>
              <h2 className="text-2xl font-semibold">1. Sustainable Building Practices</h2>
              <p className="mt-2 text-gray-600">
                Environmental consciousness is at the forefront of modern real estate development. From energy-efficient materials to green certifications, sustainability is more than just a trend—it’s a necessity. Projects such as <Link to="/Tharun" className="text-blue-600 underline">GK Tharun Enclave in Velachery</Link> embody these principles, offering eco-friendly 2BHK and 3BHK units designed for responsible living.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold">2. Smart Homes and Integrated Technology</h2>
              <p className="mt-2 text-gray-600">
                Smart homes are revolutionizing how we live, offering features like automated lighting, climate control, and security systems that can be managed from your smartphone. Our <Link to="/saishree" className="text-blue-600 underline">GK SaiShree project in Puzhuthivakkam</Link> boasts units equipped with modern technology to make your life more comfortable and connected.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold">3. Urbanization and Compact Living</h2>
              <p className="mt-2 text-gray-600">
                As cities expand, the demand for well-connected, compact living spaces is growing. Locations like <Link to="/Santhosh" className="text-blue-600 underline">Santhosh at Madipakkam</Link> address this demand by providing 2BHK and 3BHK homes in strategic urban areas that ensure convenience and accessibility.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold">4. Community-Centric Developments</h2>
              <p className="mt-2 text-gray-600">
                Homebuyers are increasingly seeking properties that foster community interaction. Integrated developments with shared amenities such as parks, gyms, and common areas are on the rise. <Link to="/Riya" className="text-blue-600 underline">Riya Enclave in Adambakkam</Link> is a prime example, offering homes with amenities that encourage a sense of belonging.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold">5. Focus on Affordable Luxury</h2>
              <p className="mt-2 text-gray-600">
                Affordability without compromising on quality is a growing priority for homebuyers. Projects like <Link to="/Tharun" className="text-blue-600 underline">GK Tharun Enclave</Link> and <Link to="/saishree" className="text-blue-600 underline">GK SaiShree</Link> combine modern design and premium features at competitive prices, catering to this demand.
              </p>
            </div>

            <div className="mt-8">
              <h3 className="text-xl font-bold">Why Choose GK Promoters?</h3>
              <p className="mt-4 text-gray-600">
                GK Promoters is at the forefront of these trends, delivering homes that match the needs of modern buyers. Whether you’re looking for a 2BHK or 3BHK unit in strategic locations like Velachery, Puzhuthivakkam, Madipakkam, or Adambakkam, we have the perfect home for you. Explore our projects:
              </p>
              <ul className="list-disc list-inside mt-4 space-y-2 text-gray-600">
                <li><Link to="/Tharun" className="text-blue-600 underline">GK Tharun Enclave – Velachery</Link></li>
                <li><Link to="/saishree" className="text-blue-600 underline">GK SaiShree – Puzhuthivakkam</Link></li>
                <li><Link to="/Santhosh" className="text-blue-600 underline">Santhosh – Madipakkam</Link></li>
                <li><Link to="/Riya" className="text-blue-600 underline">Riya Enclave – Adambakkam</Link></li>
              </ul>
              <p className="mt-6 text-gray-600">
                Stay ahead of the curve by investing in properties that reflect the future of real estate development. For more information, visit our website or contact us today!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trends2025;
